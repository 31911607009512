import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProductList from "./pages/Product/ProductList";
import SingleProduct from "./pages/Product/SingleProduct";
import ProductionForm from "./pages/Production/ProductionForm";
import ProductionRequests from "./pages/Production/ProductionRequests";
import ServicesProduct from "./pages/Production/ServicesProduct.jsx";
import SchedulePage from "./pages/Production/ScheduleList";
import ServicesDetails from "./pages/Production/ServicesDetails.jsx";
import OrderDetails from "./pages/Production/OrderDetails.jsx";
import ProgressOrder from "./pages/Production/ProgressOrder.jsx";
import ProjectDone from "./pages/Production/ProjectDone.jsx";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Profile from "./pages/Users/Profile";
import RoleManagement from "./pages/Admin/RoleManagement";
import RoleGroupMapping from "./pages/Admin/RoleGroupMapping";
import UserApproval from "./pages/Admin/UserApproval";
import LogoutButton from "./LogoutButton";
import Dashboard from "./pages/Dashboard.jsx";
import Sidebar from "./components/sidebar.jsx";
import Box from "@mui/material/Box";

// Fungsi untuk memeriksa apakah pengguna sudah login
const checkIfUserIsLoggedIn = () => {
  const authToken = localStorage.getItem("authToken");
  return !!authToken;
};

// Komponen ProtectedRoute
const ProtectedRoute = ({ element }) => {
  const isLoggedIn = checkIfUserIsLoggedIn();
  return isLoggedIn ? element : <Navigate to="/login" />;
};

// Komponen PublicRoute
const PublicRoute = ({ element }) => {
  const isLoggedIn = checkIfUserIsLoggedIn();
  return !isLoggedIn ? element : <Navigate to="/" />;
};

const drawerWidth = 240;
const drawerMinWidth = 57;

const App = () => {
  useEffect(() => {
    // Menggunakan clearTimeout dalam cleanup untuk memastikan tidak ada memory leak
    const timeoutId = setTimeout(() => {
      sessionStorage.removeItem("authToken");
    }, 8 * 60 * 60 * 1000); // 8 jam dalam milidetik
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            marginLeft: { drawerWidth }, // Sesuaikan ini jika Sidebar terbuka
            // Atau gunakan state untuk menyesuaikan secara dinamis
          }}
        >
          {" "}
          {/* 'p' adalah shorthand untuk padding */}
          <Routes>
            <Route
              path="/login"
              element={<PublicRoute element={<Login />} />}
            />
            <Route
              path="/register"
              element={<PublicRoute element={<Register />} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute element={<Profile />} />}
            />
            <Route
              path="/role-management"
              element={<ProtectedRoute element={<RoleManagement />} />}
            />
            <Route
              path="/role-group-mapping"
              element={<ProtectedRoute element={<RoleGroupMapping />} />}
            />

            <Route
              path="/products"
              element={<ProtectedRoute element={<ProductList />} />}
            />
            <Route
              path="/products/:productId/:productName?"
              element={<ProtectedRoute element={<SingleProduct />} />}
            />
            <Route
              path="/production-form"
              element={<ProtectedRoute element={<ProductionForm />} />}
            />
            <Route
              path="/services-product"
              element={<ProtectedRoute element={<ServicesProduct />} />}
            />
            <Route
              path="/service-details/:serviceId"
              element={<ServicesDetails />}
            />

            <Route
              path="/production-request"
              element={<ProtectedRoute element={<ProductionRequests />} />}
            />
            <Route
              path="/order-details/:namaPelanggan/:namaProduk/:orderId"
              element={<ProtectedRoute element={<OrderDetails />} />}
            />

            <Route
              path="/"
              element={<ProtectedRoute element={<SchedulePage />} />}
            />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/selesai"
              element={<ProtectedRoute element={<ProjectDone />} />}
            />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
