// produksi/src/pages/Auth/Login.jsx
import * as React from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  ThemeProvider,
  Typography,
  Grid,
} from "@mui/material";
import Logo from "../../assets/logo-kinami-living.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import theme from "../../theme";

const Login = () => {
  const [credentials, setCredentials] = useState({
    login: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://server.kinamiliving.com/api/auth/login",
        credentials
      );
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("userId", response.data.userId); // Simpan userId

      navigate("/profile");
      console.log(localStorage.getItem("userId"));
    } catch (error) {
      console.error("Login failed:", error.response || error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: "400px",
            padding: "30px",
            borderRadius: "10px",
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <img src={Logo} alt="Kinami Living" width="150px" />
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Email atau Username"
              name="login"
              value={credentials.login}
              onChange={handleChange}
            />
            <FormControl
              sx={{ width: "100%" }}
              variant="outlined"
              margin="normal"
            >
              <InputLabel htmlFor="password">Kata Sandi</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={credentials.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
          </form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              gap={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid>
                <Typography variant="body2">Lupa Sandi?</Typography>
              </Grid>
              <Grid>
                <Button variant="body2" href="https://wa.me/6281381448898">
                  Hubungi Team Management
                </Button>
              </Grid>
              <Typography variant="body2">Kinamiliving versi 1.1.1</Typography>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
