// produksi/src/components/StatusChip.jsx
import React from "react";
import { Chip } from "@mui/material";

// Objek untuk menentukan gaya berdasarkan status
const statusStyles = {
  Completed: {
    bgcolor: "#C8E6C9", // Hijau muda
    color: "black",
  },
  "Cetak SO": {
    bgcolor: "#FFE0B2", // Oranye muda
    color: "black",
  },
  Preparing: {
    // Menggabungkan Jok, Rangka, Jahit, dan Potong Kain ke dalam satu kategori 'Preparing'
    bgcolor: "#BBDEFB", // Biru muda
    color: "black",
  },
  Delayed: {
    bgcolor: "#FFCDD2", // Merah muda
    color: "black",
  },
  Pending: {
    bgcolor: "#4AB77A",
    color: "white",
  },
};

const StatusChip = ({ status }) => {
  const getStyle = (status) => {
    // Menangani kasus ketika status adalah salah satu dari 'Jok', 'Rangka', 'Jahit', atau 'Potong Kain'
    if (["Jok", "Rangka", "Jahit", "Potong Kain"].includes(status)) {
      return statusStyles["Preparing"];
    }
    // Mengembalikan gaya yang sesuai dari objek statusStyles atau gaya default jika status tidak dikenali
    return statusStyles[status] || { bgcolor: "#E0E0E0", color: "black" }; // Abu-abu sebagai default
  };

  return (
    <Chip
      label={status}
      size="small"
      sx={{
        ...getStyle(status),
        borderRadius: "16px",
        fontWeight: "bold",
        fontSize: "0.75rem",
        padding: "4px 8px",
      }}
    />
  );
};

export default StatusChip;
