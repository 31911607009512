import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Typography,
  Grid,
  Container,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import theme from "../../theme";
import App from "../../App";

const ServicesProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const productId = location.state?.productId;
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [operatorList, setOperatorList] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [manualCustomerName, setManualCustomerName] = useState("");
  const [manualCustomerAddress, setManualCustomerAddress] = useState("");
  const [manualCustomerMode, setManualCustomerMode] = useState(false);

  const [formData, setFormData] = useState({
    customerName: "",
    productName: "",
    damagedParts: [],
    damagedAreas: [],
    photo: null,
    operator: "",
    jobType: "",
    customerAddress: "",
    soNumber: "",
    damageDescription: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const showModal = (message) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      if (location.state && location.state.productId) {
        try {
          const response = await axios.get(
            `https://server.kinamiliving.com/api/products/${location.state.productId}`
          );
          if (response.data) {
            setProduct(response.data);
          } else {
            console.error("Product not found");
          }
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      } else {
        console.error("Product ID is not available");
      }
    };

    fetchProduct();
  }, [location.state]);

  useEffect(() => {
    if (product && product.id_produk) {
      // Komponen hanya akan dirender setelah data produk tersedia
    }
  }, [product]);

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get(
          "https://server.kinamiliving.com/api/users/operators"
        );
        if (response.data) {
          setOperatorList(response.data);
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
    };

    fetchOperators();
  }, []);

  useEffect(() => {
    const fetchCustomerInfo = async () => {
      try {
        if (productId) {
          const response = await axios.get(
            `https://server.kinamiliving.com/api/users/customers/${productId}`
          );
          if (response.data) {
            const { nama_pelanggan, alamat_pelanggan } = response.data;
            setFormData((prevState) => ({
              ...prevState,
              customerName: nama_pelanggan,
              customerAddress: alamat_pelanggan,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching customer info:", error);
      }
    };

    fetchCustomerInfo();
  }, [productId]);

  useEffect(() => {
    const fetchCustomerOptions = async () => {
      try {
        if (productId) {
          const response = await axios.get(
            `https://server.kinamiliving.com/api/users/customers/${productId}`
          );
          if (response.data) {
            setCustomerOptions(response.data);
            console.log("Customer Options:", response.data); // Tambahkan pernyataan console.log di sini
          }
        }
      } catch (error) {
        console.error("Error fetching customer options:", error);
      }
    };

    fetchCustomerOptions();
  }, [productId]);

  useEffect(() => {
    if (customerOptions.length > 0 && selectedCustomerId) {
      const selectedCustomer = customerOptions.find(
        (customer) => customer.id === selectedCustomerId
      );
      if (selectedCustomer) {
        setFormData((prevState) => ({
          ...prevState,
          customerAddress: selectedCustomer.alamat_pelanggan,
        }));
      }
    }
  }, [selectedCustomerId, customerOptions]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);

      // Membuat URL object dari file yang dipilih
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCheckboxChange = (event, category) => {
    const { checked } = event.target;
    if (checked) {
      setFormData({
        ...formData,
        [category]: [...formData[category], event.target.value],
      });
    } else {
      setFormData({
        ...formData,
        [category]: formData[category].filter(
          (item) => item !== event.target.value
        ),
      });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Di sini Anda membuat servicesData
    const servicesData = {
      id_users: formData.operator, // Menambahkan id_users ke dalam servicesData
      id_produk: product?.id_produk,
      nomor_pesanan: formData.soNumber,
      jenis_pekerjaan: formData.jobType,
      part_rusak: formData.damagedParts.join(", "), // Pastikan ini mengumpulkan data dengan benar
      area_kerusakan: formData.damagedAreas.join(", "), // Pastikan ini mengumpulkan data dengan benar
      tanggal_pengajuan: new Date().toISOString().slice(0, 10),
      catatan: formData.damageDescription,
    };

    // Tambahkan console.log di sini untuk memeriksa servicesData
    console.log("servicesData being sent:", servicesData);

    // Membuat FormData dan menambahkan servicesData ke dalamnya
    const data = new FormData();
    data.append("servicesData", JSON.stringify(servicesData));

    // Menambahkan data formulir ke FormData
    data.append(
      "customerData",
      JSON.stringify({
        id: selectedCustomerId === "manual" ? undefined : selectedCustomerId,
        nama_pelanggan: manualCustomerName,
        alamat_pelanggan: manualCustomerAddress,
      })
    );

    // Menambahkan file jika ada
    if (file) {
      data.append("bukti_foto", file);
    }

    try {
      // Sesuaikan URL dengan endpoint yang benar dari backend Anda
      const response = await axios.post(
        "https://server.kinamiliving.com/api/orders/services",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);

      // Menampilkan pemberitahuan berhasil atau redirect user
      showModal("Pengajuan berhasil dikirim.");

      // Reset form dan navigasi jika perlu
      navigate("/products"); // Sesuaikan dengan path yang diinginkan
    } catch (error) {
      console.error(error);
      showModal("Terjadi kesalahan saat mengirim pengajuan.");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ margin: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Services / Return Produk
          </Typography>
          <Button
            variant="contained"
            disabled={!product || !product.id_produk}
            sx={{
              marginRight: 2,
              paddingLeft: 2,
              paddingRight: 2,
              textTransform: "none",
            }}
            startIcon={<SendIcon />}
            onClick={handleSubmit}
          >
            Kirim Pengajuan
          </Button>
        </Toolbar>
      </AppBar>
      <Container style={{ padding: 16, marginTop: 16 }}>
        <Typography variant="h6" align="center" gutterBottom>
          Formulir Pengajuan Services & Return Produk
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required sx={{ marginBottom: 2 }}>
                <InputLabel>Nama Operator</InputLabel>
                <Select
                  name="operator"
                  value={formData.operator}
                  onChange={handleInputChange}
                  label="Nama Operator"
                >
                  <MenuItem value="">
                    <em>Pilih Operator</em>
                  </MenuItem>
                  {operatorList.map((operator) => (
                    <MenuItem key={operator.id_users} value={operator.id_users}>
                      {operator.nama_lengkap}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth required sx={{ marginBottom: 2 }}>
                <InputLabel>Jenis Pekerjaan</InputLabel>
                <Select
                  name="jobType"
                  value={formData.jobType}
                  onChange={handleInputChange}
                  label="Jenis Pekerjaan"
                >
                  <MenuItem value="">
                    <em>Pilih Pekerjaan</em>
                  </MenuItem>
                  <MenuItem value="Services">Services</MenuItem>
                  <MenuItem value="Return">Return</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Nama Produk"
                name="productName"
                value={product ? product.nama : ""}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required sx={{ marginBottom: 2 }}>
                <InputLabel>Nama Pelanggan</InputLabel>
                <Select
                  name="customerId"
                  value={selectedCustomerId}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedCustomerId(value);
                    if (value === "manual") {
                      // Jika pengguna memilih opsi "Data tidak ada", tampilkan field manual
                      setManualCustomerMode(true);
                    } else {
                      // Jika pengguna memilih pelanggan dari database, tampilkan alamat pelanggan dari database
                      setManualCustomerMode(false);
                      const selectedCustomer = customerOptions.find(
                        (customer) => customer.id === value
                      );
                      if (selectedCustomer) {
                        setFormData((prevState) => ({
                          ...prevState,
                          customerAddress: selectedCustomer.alamat_pelanggan,
                        }));
                      }
                    }
                  }}
                  label="Nama Pelanggan"
                >
                  <MenuItem value="">
                    <em>Pilih Pelanggan</em>
                  </MenuItem>
                  {customerOptions.map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>
                      {customer.nama_pelanggan}
                    </MenuItem>
                  ))}
                  <MenuItem value="manual">Data tidak ada</MenuItem>
                </Select>
              </FormControl>
              {manualCustomerMode || selectedCustomerId === "manual" ? ( // Tampilkan field manual jika manualCustomerMode true atau selectedCustomerId adalah "manual"
                <>
                  <TextField
                    fullWidth
                    label="Nama Pelanggan (Manual Input)"
                    name="manualCustomerName"
                    value={manualCustomerName}
                    onChange={(e) => setManualCustomerName(e.target.value)}
                    variant="outlined"
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    fullWidth
                    label="Alamat Pelanggan (Manual Input)"
                    name="manualCustomerAddress"
                    value={manualCustomerAddress}
                    onChange={(e) => setManualCustomerAddress(e.target.value)}
                    variant="outlined"
                    sx={{ marginBottom: 2 }}
                  />
                </>
              ) : (
                <TextField
                  fullWidth
                  label="Alamat Pelanggan"
                  name="customerAddress"
                  value={formData.customerAddress}
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                  disabled={selectedCustomerId !== ""}
                />
              )}
              <TextField
                fullWidth
                label="Nomor SO"
                name="soNumber"
                value={formData.soNumber}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Bagian yang Rusak</FormLabel>
                    <FormHelperText sx={{ marginLeft: 0 }}>
                      Bisa memilih lebih dari satu
                    </FormHelperText>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedParts")
                            }
                            value="Rangka"
                          />
                        }
                        label="Rangka"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedParts")
                            }
                            value="Kaki"
                          />
                        }
                        label="Kaki"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedParts")
                            }
                            value="Busa"
                          />
                        }
                        label="Busa"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedParts")
                            }
                            value="Pegas"
                          />
                        }
                        label="Pegas"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedParts")
                            }
                            value="Kain"
                          />
                        }
                        label="Kain"
                      />
                      {/* Tambahkan opsi bagian lainnya sesuai kebutuhan */}
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Area yang Rusak</FormLabel>
                    <FormHelperText sx={{ marginLeft: 0 }}>
                      Bisa memilih lebih dari satu
                    </FormHelperText>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedAreas")
                            }
                            value="Dudukan"
                          />
                        }
                        label="Dudukan"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedAreas")
                            }
                            value="Bantal"
                          />
                        }
                        label="Bantal"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedAreas")
                            }
                            value="Sandaran"
                          />
                        }
                        label="Sandaran"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(e, "damagedAreas")
                            }
                            value="Arm Rest"
                          />
                        }
                        label="Arm Rest"
                      />
                      {/* Tambahkan opsi posisi lainnya sesuai kebutuhan */}
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabel>Bukti Kerusakan</InputLabel>
                  <input type="file" onChange={handleFileChange}></input>
                  {imagePreviewUrl && (
                    <Box
                      mt={2}
                      textAlign="left"
                      sx={{
                        borderRadius: 2,
                        border: "1px solid #ccc",
                        width: 200,
                        height: 200,
                        display: "flex",
                        alignItems: "center", // Ini akan memusatkan gambar secara vertikal
                        justifyContent: "center", // Ini akan memusatkan gambar secara horizontal
                        overflow: "hidden", // Memastikan tidak ada bagian gambar yang keluar dari box
                      }}
                    >
                      <img
                        src={imagePreviewUrl}
                        alt="Preview"
                        style={{
                          maxHeight: 200,
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  )}
                  <TextField
                    fullWidth
                    label="Deskripsi Kerusakan"
                    name="damageDescription"
                    value={formData.damageDescription}
                    onChange={handleInputChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    sx={{ marginTop: 2 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box>
        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>{"Pengajuan Services/Return Produk"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{modalMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Tutup</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ServicesProduct;
