// produksi/src/pages/ProductionForm/ProductionForm.jsx
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Grid,
  Container,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import theme from "../../theme";

const ProductionForm = () => {
  const [product, setProduct] = useState(null);
  const [formData, setFormData] = useState({
    quantity: "",
    additionalNotes: "",
    customJenisKain: "",
    customDudukan: "",
    customJenisKaki: "",
    customJumlahBantalPeluk: "",
    customJumlahBantalSandaran: "",
    customTermasukPuff: false, // Ini harus boolean
    customKantongRemote: false, // Menambahkan field untuk "Kantong Remote"
    customLength: "",
    customWidth: "",
    customHeight: "",
    tanggalPemesanan: "",
    color: "", // Nilai default untuk color
    selectedFinishing: "", // Nilai default untuk finishing
  });
  const [kancingBantalPeluk, setKancingBantalPeluk] = useState(false);
  const [kancingBantalSandaran, setKancingBantalSandaran] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [colors, setColors] = useState([]);
  const [finishings, setFinishings] = useState([]);
  const [isCustom, setIsCustom] = useState(false);
  const [customOptions, setCustomOptions] = useState({
    jenisKain: false,
    dudukan: false,
    jenisKaki: false,
    jumlahBantalPeluk: false,
    jumlahBantalSandaran: false,
    termasukPuff: false,
    customDimensions: false, // Add this option
    customKantongRemote: false, // Tambahkan ini
  });
  const [dataKain, setDataKain] = useState([]);
  const [selectedCustomKain, setSelectedCustomKain] = useState("");
  const [jenisKakiData, setJenisKakiData] = useState([]);
  const [selectedJenisKaki, setSelectedJenisKaki] = useState("");
  const [jenisDudukanOptions, setJenisDudukanOptions] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [productionDeadline, setProductionDeadline] = useState("");

  const [operatorOptions, setOperatorOptions] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [operatorList, setOperatorList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get(
          "https://server.kinamiliving.com/api/users/operators"
        );
        if (response.data) {
          setOperatorList(response.data); // Simpan data operator ke dalam state
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
    };

    fetchOperators();
  }, []);

  useEffect(() => {
    const fetchJenisKaki = async () => {
      try {
        const response = await axios.get(
          "https://server.kinamiliving.com/api/kaki"
        );
        setJenisKakiData(response.data); // Atur data jenis kaki dari server
      } catch (error) {
        console.error("Error fetching jenis kaki:", error);
      }
    };

    fetchJenisKaki();
  }, []);

  /* Mengambil data jenis finishing */
  useEffect(() => {
    const fetchFinishings = async () => {
      try {
        const response = await axios.get(
          "https://server.kinamiliving.com/api/finishings"
        );
        setFinishings(response.data);
      } catch (error) {
        console.error("Error fetching finishings:", error);
      }
    };

    fetchFinishings();
  }, []);

  //state jenis dudukan
  useEffect(() => {
    const fetchJenisDudukan = async () => {
      try {
        const response = await axios.get(
          "https://server.kinamiliving.com/api/dudukan"
        );
        setJenisDudukanOptions(response.data);
      } catch (error) {
        console.error("Error fetching jenis dudukan:", error);
      }
    };

    fetchJenisDudukan();
  }, []);

  // Gunakan useEffect untuk mengambil data kain dari server
  useEffect(() => {
    const fetchDataKain = async () => {
      try {
        const response = await fetch(
          "https://server.kinamiliving.com/api/kain"
        );
        if (response.ok) {
          const data = await response.json();
          setDataKain(data); // Set state dataKain dengan data dari server
        } else {
          console.error("Error fetching data kain:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data kain:", error);
      }
    };

    // Panggil fungsi untuk mengambil data saat komponen dimuat
    fetchDataKain();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      if (location.state && location.state.productId) {
        try {
          const response = await axios.get(
            `https://server.kinamiliving.com/api/products/${location.state.productId}`
          );
          if (response.data) {
            setProduct(response.data); // Update state product dengan data yang diterima
            console.log("Produk berhasil diambil:", response.data);
          } else {
            // Handle kasus jika data produk tidak ditemukan
            console.error("Produk tidak ditemukan");
          }
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      } else {
        // Handle kasus jika productId tidak ada di location.state
        console.error("Product ID is not available");
      }
    };

    fetchProduct();
  }, [location.state]);
  // Tambahkan useEffect untuk memastikan bahwa komponen hanya dirender setelah data produk tersedia
  useEffect(() => {
    if (product && product.id_produk) {
      // Data produk tersedia, render komponen
    }
  }, [product]);

  useEffect(() => {
    console.log("customOptions.jenisKain:", customOptions.jenisKain);
    console.log("selectedCustomKain:", selectedCustomKain);
    const fetchCustomColors = async () => {
      try {
        const response = await axios.get(
          `https://server.kinamiliving.com/api/colors/${selectedCustomKain}`
        );
        setColors(response.data);
        console.log("fetchCustomColors has been executed"); // Tambahkan log ini
      } catch (error) {
        console.error("Error fetching custom colors:", error);
      }
    };

    const fetchStandardColors = async () => {
      if (product && product.id_kain) {
        try {
          const response = await axios.get(
            `https://server.kinamiliving.com/api/colors/${product.id_kain}`
          );
          setColors(response.data);
          console.log("fetchStandardColors has been executed"); // Tambahkan log ini
        } catch (error) {
          console.error("Error fetching standard colors:", error);
        }
      }
    };

    if (customOptions.jenisKain && selectedCustomKain) {
      fetchCustomColors();
    } else if (!customOptions.jenisKain && product && product.id_kain) {
      fetchStandardColors();
    }
  }, [product, customOptions.jenisKain, selectedCustomKain]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    switch (name) {
      case "isCustom":
        if (type === "checkbox") {
          setFormData((prevFormData) => ({
            ...prevFormData,
            customLength: "",
            customWidth: "",
            customHeight: "",
            selectedCustomKain: "",
            selectedJenisKaki: "",
            selectedFinishing: "",
            customJumlahBantalPeluk: "",
            customJumlahBantalSandaran: "",
            customTermasukPuff: false,
            // Dan lain-lain
          }));
        }
        break;
      case "customerName":
        setCustomerName(value);
        break;
      case "customerAddress":
        setCustomerAddress(value);
        break;
      case "selectedOperator":
        setSelectedOperator(value);
        break;
      case "selectedCustomKain":
        setSelectedCustomKain(value);
        break;
      case "selectedJenisKaki":
        setSelectedJenisKaki(value);
        break;
      default:
        if (type === "checkbox") {
          setFormData((prevFormData) => ({ ...prevFormData, [name]: checked }));
        }
        if (name === "productionDeadline") {
          setProductionDeadline(value);
        } else {
          setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }
        break;
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const toKebabCase = (str) => {
    return str
      .toLowerCase() // Ubah menjadi huruf kecil
      .replace(/[\s_]+/g, "-") // Ganti spasi atau underscore dengan tanda strip
      .replace(/[^\w-]+/g, "") // Hapus semua karakter selain huruf, angka, dan tanda strip
      .replace(/--+/g, "-"); // Ganti multiple tanda strip dengan satu
  };

  const handleSubmit = async (e) => {
    console.log("Submit initiated", formData); // Debug

    // Validasi jika produk belum dipilih
    if (!product || !product.id_produk) {
      alert("Produk belum dipilih atau tidak valid");
      return;
    }

    // Struktur data sesuai dengan format yang diterima oleh backend
    const customerData = {
      nama_pelanggan: customerName,
      alamat_pelanggan: customerAddress,
      // nomor_hp di-null-kan
    };

    const productionRequestData = {
      id_sales: selectedOperator,
      id_produk: product.id_produk,
      tanggal_pemesanan: formData.tanggalPemesanan,
      deadline_produksi: productionDeadline,
      jumlah: formData.quantity,
      catatan: formData.additionalNotes,
      // status dapat di-null-kan atau diatur di sisi server
    };

    let sofaOrderData = {
      id_warna: formData.color,
      iscustom: isCustom,
      id_finishing: formData.selectedFinishing || null, // Finishing untuk produk custom dan non-custom
    };

    if (isCustom) {
      // Tambahkan field tambahan hanya jika produknya custom
      sofaOrderData = {
        ...sofaOrderData,
        panjang: formData.customLength || null,
        lebar: formData.customWidth || null,
        tinggi: formData.customHeight || null,
        id_kain: selectedCustomKain || null,
        id_dudukan: formData.customDudukan || null,
        id_kaki: formData.customJenisKaki || null,
        jml_bantal_peluk: formData.customJumlahBantalPeluk || null,
        jml_bantal_sandaran: formData.customJumlahBantalSandaran || null,
        kantong_remote: formData.customKantongRemote || null, // Menambahkan "Kantong Remote" ke dalam data
        is_puff: formData.customTermasukPuff || false,
        kancing_bantal_peluk: kancingBantalPeluk,
        kancing_bantal_sandaran: kancingBantalSandaran,
      };
    }

    try {
      const response = await axios.post(
        "https://server.kinamiliving.com/api/orders/create",
        {
          customerData,
          productionRequestData,
          sofaOrderData,
        }
      );

      const productionRequestId = response.data.productionRequestId; // Menggunakan productionRequestId bukan orderId

      // handle response
      // Jika berhasil, setel pesan modal dan tampilkan modal konfirmasi
      setNotificationMessage("Pengajuan berhasil dikirimkan.");
      setShowNotificationDialog(true);
      // Redirect ke halaman sebelumnya setelah 2 detik
      setTimeout(() => {
        setShowNotificationDialog(false); // Tutup modal

        // Tambahan: Arahkan pengguna ke halaman detail pesanan
        const formattedNamaPelanggan = toKebabCase(customerData.nama_pelanggan);
        const formattedNamaProduk = toKebabCase(product.nama);
        navigate(
          `/order-details/${formattedNamaPelanggan}/${formattedNamaProduk}/${productionRequestId}`
        );
      }, 2000);
    } catch (error) {
      // Handle Error
      // Sesuaikan path berikut berdasarkan struktur error dari backend Anda
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Terjadi kesalahan saat mengirim pengajuan. Silakan coba lagi.";
      setNotificationMessage(errorMessage);
      setShowNotificationDialog(true);
    }
  };

  const handleOpenConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const handleCustomChange = (e) => {
    setIsCustom(e.target.checked);
    setSelectedCustomKain(""); // Setel jenis kain custom menjadi kosong saat status custom berubah
  };

  const handleCustomOptionChange = (option) => {
    if (option === "customDimensions") {
      setFormData({
        ...formData,
        customLength: "", // Clear custom dimensions when toggling
        customWidth: "",
        customHeight: "",
      });
    }
    setCustomOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };

  const renderCustomFields = () => {
    if (isCustom) {
      return (
        <>
          {/* Custom Dimensions */}
          {customOptions.customDimensions && (
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: 2,
              }}
            >
              <Grid item xs={4}>
                <TextField
                  label="Panjang"
                  type="number"
                  name="customLength"
                  value={formData.customLength}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Lebar"
                  type="number"
                  name="customWidth"
                  value={formData.customWidth}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Tinggi"
                  type="number"
                  name="customHeight"
                  value={formData.customHeight}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          {/* Custom Jenis Kain */}
          {customOptions.jenisKain && (
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: 2,
              }}
            >
              {/* Field untuk Jenis Kain Custom */}
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Jenis Kain Custom</InputLabel>
                  <Select
                    name="customJenisKain"
                    value={selectedCustomKain}
                    onChange={(e) => setSelectedCustomKain(e.target.value)}
                    label="Jenis Kain Custom"
                  >
                    {dataKain.map((kain) => (
                      <MenuItem key={kain.id_kain} value={kain.id_kain}>
                        {kain.kain}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Field untuk Warna */}
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Warna</InputLabel>
                  <Select
                    name="color"
                    value={formData.color}
                    onChange={handleChange}
                    label="Warna"
                  >
                    <MenuItem value="">
                      <em>Pilih Warna</em>
                    </MenuItem>
                    {colors.map((color) => (
                      <MenuItem key={color.id_warna} value={color.id_warna}>
                        {color.warna}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {/* Custom Dudukan */}
          {customOptions.dudukan && (
            <FormControl
              fullWidth
              sx={{
                marginBottom: 2,
              }}
            >
              <InputLabel>Dudukan Custom</InputLabel>
              <Select
                name="customDudukan"
                value={formData.customDudukan}
                onChange={handleChange}
                label="Dudukan Custom"
              >
                <MenuItem value="">
                  <em>Pilih Dudukan</em>
                </MenuItem>
                {jenisDudukanOptions.map((dudukan) => (
                  <MenuItem key={dudukan.id_dudukan} value={dudukan.id_dudukan}>
                    {dudukan.dudukan}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {customOptions.jenisKaki && (
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: 2,
              }}
            >
              {/* Field untuk Jenis Kaki */}
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Jenis Kaki</InputLabel>
                  <Select
                    name="customJenisKaki"
                    value={formData.customJenisKaki}
                    onChange={handleChange}
                    label="Jenis Kaki"
                  >
                    <MenuItem value="">
                      <em>Pilih Jenis Kaki</em>
                    </MenuItem>
                    {jenisKakiData.map((kaki) => (
                      <MenuItem key={kaki.id_kaki} value={kaki.id_kaki}>
                        {kaki.jenis_kaki}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Conditional rendering untuk Jenis Finishing Kaki */}
              {formData.customJenisKaki &&
                ["Skandinavian", "Balok Kayu"].includes(
                  jenisKakiData.find(
                    (kaki) => kaki.id_kaki === Number(formData.customJenisKaki)
                  )?.jenis_kaki
                ) && (
                  <Grid item xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel>Jenis Finishing Kaki</InputLabel>
                      <Select
                        name="selectedFinishing"
                        value={formData.selectedFinishing}
                        onChange={handleChange}
                        label="Jenis Finishing Kaki"
                      >
                        <MenuItem value="">
                          <em>Pilih Jenis Finishing Kaki</em>
                        </MenuItem>
                        {finishings.map((finishing) => (
                          <MenuItem
                            key={finishing.id_finishing}
                            value={finishing.id_finishing}
                          >
                            {finishing.finishing}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
            </Grid>
          )}

          {/* Custom Jumlah Bantal Peluk */}
          {customOptions.jumlahBantalPeluk && (
            <>
              <TextField
                label="Jumlah Bantal Peluk Custom"
                type="number"
                name="customJumlahBantalPeluk"
                value={formData.customJumlahBantalPeluk}
                onChange={handleChange}
                fullWidth
              />

              <FormControlLabel
                sx={{
                  marginBottom: 2,
                }}
                control={
                  <Checkbox
                    checked={kancingBantalPeluk}
                    onChange={(e) => setKancingBantalPeluk(e.target.checked)}
                    name="kancingBantalPeluk"
                  />
                }
                label="Kancing Bantal Peluk"
              />
            </>
          )}

          {/* Custom Jumlah Bantal Sandaran */}
          {customOptions.jumlahBantalSandaran && (
            <>
              <TextField
                label="Jumlah Bantal Sandaran Custom"
                type="number"
                name="customJumlahBantalSandaran"
                value={formData.customJumlahBantalSandaran}
                onChange={handleChange}
                fullWidth
              />
              <FormControlLabel
                sx={{
                  marginBottom: 2,
                }}
                control={
                  <Checkbox
                    checked={kancingBantalSandaran}
                    onChange={(e) => setKancingBantalSandaran(e.target.checked)}
                    name="kancingBantalSandaran"
                  />
                }
                label="Kancing Bantal Sandaran"
              />
            </>
          )}

          {/* Custom Jumlah Kantong Remote */}
          {customOptions.customKantongRemote && (
            <TextField
              label="Kantong Remote Custom"
              type="number"
              name="customKantongRemote"
              value={formData.customKantongRemote}
              onChange={handleChange}
              fullWidth
              sx={{
                marginBottom: 2,
              }}
            />
          )}

          {/* Add other custom fields if necessary */}
          {/* ... */}
        </>
      );
    }
    return null;
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  // Gabungkan spesifikasi khusus produk menjadi satu string
  const spesifikasiKhusus = product
    ? `Style: ${product.nama_style || "-"}\n` +
      `Jenis Kain: ${product.jenis_kain || "-"}\n` +
      `Dudukan: ${product.jenis_dudukan || "-"}\n` +
      `Jenis Kaki: ${product.jenis_kaki || "-"}\n` +
      `Jumlah Bantal Peluk: ${product.bantal_peluk || "-"}\n` +
      `Jumlah Bantal Sandaran: ${product.bantal_sandaran || "-"}\n` +
      `Termasuk Puff: ${product.puff ? "Ya" : "Tidak"}\n` +
      `Kantong Remote: ${product.kantong_remot || "-"}`
    : "";

  console.log("Product state at render:", product);
  console.log("Product ID:", product.id_produk);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Pengajuan Produksi
          </Typography>
          <Button
            variant="contained"
            disabled={!product || !product.id_produk} // Tombol submit tidak aktif jika tidak ada produk yang dipilih
            sx={{
              marginRight: 2, // Menambahkan margin di sebelah kanan tombol
              paddingLeft: 2,
              paddingRight: 2,
              textTransform: "none",
            }}
            startIcon={<SendIcon />}
            onClick={handleOpenConfirmDialog}
          >
            Kirim Pengajuan
          </Button>
        </Toolbar>
      </AppBar>
      <Container style={{ padding: 16, marginTop: 16 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {/* Field Nama Pelanggan */}
                <Grid item xs={12}>
                  <TextField
                    label="Nama Pelanggan"
                    type="text"
                    name="customerName"
                    value={customerName}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                {/* Field Alamat Pelanggan */}
                <Grid item xs={12}>
                  <TextField
                    label="Alamat Pelanggan"
                    name="customerAddress"
                    value={customerAddress}
                    onChange={handleChange}
                    required
                    multiline
                    fullWidth
                  />
                </Grid>

                {/* Field Tanggal Pemesanan */}
                <Grid item xs={12}>
                  <TextField
                    label="Tanggal Pemesanan"
                    type="date"
                    name="tanggalPemesanan"
                    value={formData.tanggalPemesanan}
                    onChange={handleChange}
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                {/* Field Nama Produk */}
                <Grid item xs={12}>
                  <TextField
                    label="Nama Produk"
                    type="text"
                    value={product.nama}
                    disabled
                    fullWidth
                  />
                </Grid>

                {/* Field Kategori dan Subkategori */}
                <Grid item xs={12}>
                  <TextField
                    label="Kategori dan Subkategori"
                    type="text"
                    value={`${product.kategori} -- ${product.subkategori}`}
                    disabled
                    fullWidth
                  />
                </Grid>

                {/* Field Dimensi */}
                <Grid item xs={12}>
                  <TextField
                    label="Dimensi"
                    type="text"
                    value={`${product.panjang} cm x ${product.lebar} cm x ${product.tinggi} cm`}
                    disabled
                    fullWidth
                  />
                </Grid>

                {/* Tampilkan spesifikasi khusus produk */}
                <Grid item xs={12}>
                  <TextField
                    label={`Spesifikasi  ${product.nama}`}
                    value={spesifikasiKhusus}
                    disabled
                    multiline
                    rows={8}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Nama Operator</InputLabel>
                <Select
                  name="selectedOperator"
                  value={selectedOperator}
                  onChange={handleChange}
                  label="Nama Operator"
                >
                  <MenuItem value="">
                    <em>Pilih Operator</em>
                  </MenuItem>
                  {operatorList.map((operator) => (
                    <MenuItem key={operator.id_users} value={operator.id_users}>
                      {operator.nama_lengkap}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Checkbox Apakah Produk Custom */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isCustom"
                      checked={isCustom}
                      onChange={handleCustomChange}
                    />
                  }
                  label="Apakah Produk Custom"
                />
              </FormGroup>

              {/* Checkbox untuk Opsi Custom */}
              <Typography variant="subtitle1">
                Pilih apa yang ingin di-custom:
              </Typography>
              <FormGroup>
                {/* Main Grid container for two-column layout */}
                <Grid container spacing={2}>
                  {/* Left Column */}
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      {/* Each checkbox in its own Grid item for separate rows */}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="customDimensions"
                              checked={customOptions.customDimensions}
                              onChange={() =>
                                handleCustomOptionChange("customDimensions")
                              }
                              disabled={!isCustom}
                            />
                          }
                          label="Ukuran"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="jenisKain"
                              checked={customOptions.jenisKain}
                              onChange={() =>
                                handleCustomOptionChange("jenisKain")
                              }
                              disabled={!isCustom}
                            />
                          }
                          label="Jenis Kain"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="dudukan"
                              checked={customOptions.dudukan}
                              onChange={() =>
                                handleCustomOptionChange("dudukan")
                              }
                              disabled={!isCustom}
                            />
                          }
                          label="Dudukan"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="jenisKaki"
                              checked={customOptions.jenisKaki}
                              onChange={() =>
                                handleCustomOptionChange("jenisKaki")
                              }
                              disabled={!isCustom}
                            />
                          }
                          label="Jenis Kaki"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      {/* Each checkbox in its own Grid item for separate rows */}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="jumlahBantalPeluk"
                              checked={customOptions.jumlahBantalPeluk}
                              onChange={() =>
                                handleCustomOptionChange("jumlahBantalPeluk")
                              }
                              disabled={!isCustom}
                            />
                          }
                          label="Jumlah Bantal Peluk"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="jumlahBantalSandaran"
                              checked={customOptions.jumlahBantalSandaran}
                              onChange={() =>
                                handleCustomOptionChange("jumlahBantalSandaran")
                              }
                              disabled={!isCustom}
                            />
                          }
                          label="Jumlah Bantal Sandaran"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="customKantongRemote"
                              checked={customOptions.customKantongRemote}
                              onChange={() =>
                                handleCustomOptionChange("customKantongRemote")
                              }
                              disabled={!isCustom}
                            />
                          }
                          label="Kantong Remote"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="customTermasukPuff"
                              checked={formData.customTermasukPuff}
                              onChange={handleChange}
                              disabled={!isCustom}
                            />
                          }
                          label="Termasuk Puff?"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormGroup>

              {renderCustomFields()}

              <Grid container spacing={2}>
                {/* Field untuk memilih warna */}
                {!customOptions.jenisKain && (
                  <Grid item xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel>Warna</InputLabel>
                      <Select
                        name="color"
                        value={formData.color}
                        onChange={handleChange}
                        label="Warna"
                      >
                        <MenuItem value="">
                          <em>Pilih Warna</em>
                        </MenuItem>
                        {colors.map((color) => (
                          <MenuItem key={color.id_warna} value={color.id_warna}>
                            {color.warna}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {/* Field untuk memilih jenis finishing kaki */}
                {!customOptions.jenisKaki &&
                  product.jenis_kaki &&
                  (product.jenis_kaki === "Skandinavian" ||
                    product.jenis_kaki === "Balok Kayu") && (
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <InputLabel>Jenis Finishing Kaki</InputLabel>
                        <Select
                          name="selectedFinishing"
                          value={formData.selectedFinishing}
                          onChange={handleChange}
                          label="Jenis Finishing Kaki"
                        >
                          <MenuItem value="">
                            <em>Pilih Jenis Finishing Kaki</em>
                          </MenuItem>
                          {finishings.map((finishing) => (
                            <MenuItem
                              key={finishing.id_finishing}
                              value={finishing.id_finishing}
                            >
                              {finishing.finishing}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                {/* Field Jumlah */}
                <Grid item xs={12}>
                  <TextField
                    label="Jumlah"
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                {/* Field Deadline Produksi */}
                <Grid item xs={12}>
                  <TextField
                    label="Deadline Produksi"
                    type="date"
                    name="productionDeadline"
                    value={productionDeadline}
                    onChange={handleChange}
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                {/* Field Catatan Tambahan */}
                <Grid item xs={12}>
                  <TextField
                    label="Catatan Tambahan"
                    name="additionalNotes"
                    value={formData.additionalNotes}
                    onChange={handleChange}
                    multiline
                    fullWidth
                    inputProps={{ maxLength: 86 }}
                  />
                  <Typography>
                    {" "}
                    {formData.additionalNotes.length}/86 karakter
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Dialog
        open={showNotificationDialog}
        onClose={() => setShowNotificationDialog(false)}
      >
        <DialogTitle>Pemberitahuan</DialogTitle>
        <DialogContent>
          <DialogContentText>{notificationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNotificationDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pastikan data yang Anda ajukan sudah benar. Data yang sudah masuk
            tidak bisa di edit!. Apakah Anda yakin ingin mengirim pengajuan ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cek Lagi</Button>
          <Button
            onClick={() => {
              setShowConfirmDialog(false);
              handleSubmit();
            }}
          >
            Kirim Pengajuan
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductionForm;
