//produksi/src/pages/Production/ProductionRequests.jsx
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import moment from "moment-timezone";
import {
  Container,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  Checkbox,
  Modal,
  TextField,
  Grid,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../assets/custom-calendar.css";

const convertDateForSorting = (dateString) => {
  const parts = dateString.split("-");
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

const sortByDate = (array, key, direction) => {
  return array.sort((a, b) => {
    const dateA = convertDateForSorting(a[key]);
    const dateB = convertDateForSorting(b[key]);
    return (dateA - dateB) * (direction === "ascending" ? 1 : -1);
  });
};

const ProductionRequests = () => {
  const [requests, setRequests] = useState([]);
  const navigate = useNavigate();
  const [servicesList, setServicesList] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://server.kinamiliving.com/api/orders/list"
        );
        const formattedData = response.data.map((item) => ({
          ...item,
          tanggal_pemesanan: convertToJakartaTime(item.tanggal_pemesanan),
          deadline_produksi: convertToJakartaTime(item.deadline_produksi),
        }));
        setRequests(formattedData);
      } catch (error) {
        console.error("Error fetching production requests:", error);
      }
    };

    fetchData();
    fetchServicesList(); // Panggil fungsi fetchServicesList() di sini
  }, []);

  const fetchServicesList = async () => {
    try {
      const response = await axios.get(
        "https://server.kinamiliving.com/api/orders/serviceslist"
      );
      setServicesList(response.data);
    } catch (error) {
      console.error("Error fetching services list:", error);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedRequests = useMemo(() => {
    let sortedData = [...requests];

    if (sortConfig.key !== null) {
      if (
        sortConfig.key === "tanggal_pemesanan" ||
        sortConfig.key === "deadline_produksi"
      ) {
        // Pengurutan khusus untuk kolom tanggal
        sortedData = sortByDate(
          sortedData,
          sortConfig.key,
          sortConfig.direction
        );
      } else {
        // Pengurutan umum untuk kolom lain
        sortedData.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
    }

    return sortedData;
  }, [sortConfig, requests]);

  const convertToJakartaTime = (dateString) => {
    return moment(dateString).tz("Asia/Jakarta").format("DD-MM-YYYY");
  };

  const toKebabCase = (str) => {
    return str
      .toLowerCase() // Ubah menjadi huruf kecil
      .replace(/[\s_]+/g, "-") // Ganti spasi atau underscore dengan tanda strip
      .replace(/[^\w-]+/g, "") // Hapus semua karakter selain huruf, angka, dan tanda strip
      .replace(/--+/g, "-"); // Ganti multiple tanda strip dengan satu
  };

  const handleServiceListRowClick = (serviceId) => {
    // Logika navigasi atau tindakan lain ketika baris pada servicesList diklik
    // Contoh: navigate ke detail service dengan serviceId sebagai parameter
    navigate(`/service-details/${serviceId}`);
  };

  const handleRowClick = (namaPelanggan, namaProduk, orderId) => {
    const formattedNamaPelanggan = toKebabCase(namaPelanggan);
    const formattedNamaProduk = toKebabCase(namaProduk);
    navigate(
      `/order-details/${formattedNamaPelanggan}/${formattedNamaProduk}/${orderId}`
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Permintaan Produksi{" "}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ ml: 5, mr: 2, mt: 2 }}>
        <Grid container spacing={3}>
          <TableContainer
            component={Paper}
            sx={{
              mt: 2,
            }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <TableCell
                    onClick={() => requestSort("ID")}
                    sx={{
                      cursor: "pointer",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    ID
                    {sortConfig.key === "ID" &&
                      (sortConfig.direction === "ascending" ? (
                        <ArrowUpwardIcon fontSize="xs" />
                      ) : (
                        <ArrowDownwardIcon fontSize="xs" />
                      ))}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Nama Pelanggan</TableCell>
                  <TableCell sx={{ color: "white" }}>Nama Produk</TableCell>
                  <TableCell sx={{ color: "white" }}>Nomor Pesanan</TableCell>
                  <TableCell sx={{ color: "white" }}>Jenis Pekerjaan</TableCell>
                  <TableCell sx={{ color: "white" }}>Part Rusak</TableCell>
                  <TableCell sx={{ color: "white" }}>Area Kerusakan</TableCell>
                  <TableCell sx={{ color: "white" }}>Bukti Foto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicesList.map((service) => (
                  <TableRow
                    key={service["ID services"]}
                    hover
                    onClick={() =>
                      handleServiceListRowClick(service["ID services"])
                    } // Gunakan handleServiceListRowClick di sini
                  >
                    <TableCell component="th" scope="row">
                      {service["ID services"]}
                    </TableCell>
                    <TableCell>{service["Nama Pelanggan"]}</TableCell>
                    <TableCell>{service["Nama Produk"]}</TableCell>
                    <TableCell>{service["Nomor Pesanan"]}</TableCell>
                    <TableCell>{service["Jenis Pekerjaan"]}</TableCell>
                    <TableCell>{service["Part yg rusak"]}</TableCell>
                    <TableCell>{service["Area yg rusak"]}</TableCell>
                    <TableCell>
                      <img
                        src={service["Foto"]}
                        style={{ maxWidth: "50px", maxHeight: "50px" }}
                        alt="Foto"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            component={Paper}
            sx={{
              mt: 2,
            }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <TableCell
                    onClick={() => requestSort("order_id")}
                    sx={{
                      cursor: "pointer",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    ID
                    {sortConfig.key === "order_id" &&
                      (sortConfig.direction === "ascending" ? (
                        <ArrowUpwardIcon fontSize="xs" />
                      ) : (
                        <ArrowDownwardIcon fontSize="xs" />
                      ))}
                  </TableCell>

                  <TableCell sx={{ color: "white" }}>Sales</TableCell>
                  <TableCell sx={{ color: "white" }}>Nama Pelanggan</TableCell>
                  <TableCell sx={{ color: "white" }}>Nama Produk</TableCell>
                  <TableCell
                    onClick={() => requestSort("tanggal_pemesanan")}
                    sx={{
                      cursor: "pointer",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    Tanggal Pemesanan
                    {sortConfig.key === "tanggal_pemesanan" &&
                      (sortConfig.direction === "ascending" ? (
                        <ArrowUpwardIcon fontSize="xs" />
                      ) : (
                        <ArrowDownwardIcon fontSize="xs" />
                      ))}
                  </TableCell>
                  <TableCell
                    onClick={() => requestSort("deadline_produksi")}
                    sx={{
                      cursor: "pointer",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    Deadline Produksi
                    {sortConfig.key === "deadline_produksi" &&
                      (sortConfig.direction === "ascending" ? (
                        <ArrowUpwardIcon fontSize="xs" />
                      ) : (
                        <ArrowDownwardIcon fontSize="xs" />
                      ))}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Pekerjaan</TableCell>
                  <TableCell sx={{ color: "white" }}>Jumlah</TableCell>
                  <TableCell sx={{ color: "white" }}>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRequests.map((request) => (
                  <TableRow
                    key={request.id}
                    hover
                    onClick={() =>
                      handleRowClick(
                        request.nama_pelanggan,
                        request.nama_produk,
                        request.order_id
                      )
                    }
                  >
                    <TableCell component="th" scope="row">
                      {request.order_id}
                    </TableCell>
                    {/* Periksa apakah Anda benar ingin menampilkan 'nama_pelanggan' di kolom 'Operators' */}
                    <TableCell>{request.sales}</TableCell>
                    <TableCell>{request.nama_pelanggan}</TableCell>
                    <TableCell>{request.nama_produk}</TableCell>
                    <TableCell>{request.tanggal_pemesanan}</TableCell>
                    <TableCell>{request.deadline_produksi}</TableCell>
                    <TableCell>{request.status_pekerjaan}</TableCell>
                    <TableCell>{request.jumlah}</TableCell>

                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleRowClick(
                            request.nama_pelanggan,
                            request.nama_produk,
                            request.order_id
                          )
                        }
                      >
                        {" "}
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductionRequests;
