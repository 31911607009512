// produksi/src/pages/Admin/RoleManagement.jsx
import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";

const RoleManagement = () => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // Ambil data peran dari server

    const roles = [
      {
        id: 1,
        name: "Administrator",
        description: "Memiliki akses penuh ke semua bagian sistem.",
      },
      {
        id: 2,
        name: "Editor",
        description: "Dapat membuat, mengedit, dan menghapus konten.",
      },
      {
        id: 3,
        name: "Pengguna Biasa",
        description: "Dapat mengakses dan melihat konten.",
      },
    ];

    setRoles(roles);
  }, []);

  const handleCreateRole = () => {
    // Buat peran baru

    const newRole = {
      id: 4,
      name: "Manajer",
      description: "Dapat mengelola pengguna dan peran.",
    };

    setRoles([...roles, newRole]);
  };

  const handleEditRole = (id) => {
    // Edit peran

    const role = roles.find((role) => role.id === id);

    // Tampilkan dialog untuk mengedit peran

    const updatedRole = {
      ...role,
      name: "Manajer Proyek",
      description: "Dapat mengelola proyek dan tugas.",
    };

    setRoles(roles.map((role) => (role.id === id ? updatedRole : role)));
  };

  const handleDeleteRole = (id) => {
    // Hapus peran

    setRoles(roles.filter((role) => role.id !== id));
  };

  return (
    <Paper sx={{ maxWidth: "600px", margin: "20px auto", padding: "20px" }}>
      <h1>Manajemen Peran</h1>
      <Button variant="contained" onClick={handleCreateRole}>
        Buat Peran Baru
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nama</TableCell>
            <TableCell>Deskripsi</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.map((role) => (
            <TableRow key={role.id}>
              <TableCell>{role.id}</TableCell>
              <TableCell>{role.name}</TableCell>
              <TableCell>{role.description}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => handleEditRole(role.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeleteRole(role.id)}
                >
                  Hapus
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default RoleManagement;
