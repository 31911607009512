// produksi/src/pages/Production/ProjectDone.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AppBar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import theme from "../../theme";

const ProjectDone = () => {
  const [completedProjects, setCompletedProjects] = useState([]);

  useEffect(() => {
    const fetchCompletedProjects = async () => {
      try {
        const response = await axios.get(
          "https://server.kinamiliving.com/api/orders/done"
        );
        setCompletedProjects(response.data);
      } catch (error) {
        console.error("Error fetching completed projects:", error);
      }
    };

    fetchCompletedProjects();
  }, []);

  // Fungsi untuk memformat tanggal ke dd-mm-yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Bulan dimulai dari 0
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ marginRight: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Project Selesai
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ padding: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
              <TableRow>
                <TableCell sx={{ color: "white" }}>ID</TableCell>
                <TableCell sx={{ color: "white" }}>Sales</TableCell>
                <TableCell sx={{ color: "white" }}>Nama Produk</TableCell>
                <TableCell sx={{ color: "white" }}>Jumlah</TableCell>
                <TableCell sx={{ color: "white" }}>Pelanggan</TableCell>
                <TableCell sx={{ color: "white" }}>Tanggal Selesai</TableCell>
                <TableCell sx={{ color: "white" }}>Foto / Bukti</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedProjects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell>{project.id_orders}</TableCell>
                  <TableCell>{project.sales}</TableCell>
                  <TableCell>{project.nama_produk}</TableCell>
                  <TableCell>{project.jumlah}</TableCell>
                  <TableCell>{project.nama_pelanggan}</TableCell>
                  <TableCell>{formatDate(project.tanggal)}</TableCell>
                  <TableCell>
                    <img
                      src={project.foto}
                      alt={`Foto Bukti ${project.id}`}
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ProjectDone;
