import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  CircularProgress,
  TextField,
  Grid,
  Stack,
} from "@mui/material";
import axios from "axios";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await axios.get(
          `https://server.kinamiliving.com/api/users/profile/${userId}`
        );
        setUser(response.data);
        setEditData(response.data);
      } catch (error) {
        console.error(
          "Gagal mengambil data profil pengguna:",
          error.response || error
        );
      }
    };

    fetchUserProfile();
  }, []);

  const handleInputChange = (event) => {
    setEditData({ ...editData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userId = localStorage.getItem("userId");
      const formData = new FormData();
      Object.keys(editData).forEach((key) => {
        formData.append(key, editData[key]);
      });
      await axios.put(
        `https://server.kinamiliving.com/api/users/profile/update/${userId}`,
        formData
      );
      setIsEditing(false);
      setUser(editData);
      setPreview(null);
    } catch (error) {
      console.error(
        "Gagal mengupdate profil pengguna:",
        error.response || error
      );
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setEditData({ ...editData, foto_profil: file });
      setPreview(URL.createObjectURL(file));
    }
  };

  if (!user) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid Container>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        sx={{
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          maxWidth: 400,
        }}
      >
        <Paper
          elevation={4}
          sx={{
            p: 3,
            my: 2,
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            marginTop: "10vh", // Menambahkan margin-top: "20vh" untuk membuat paper di tengah layar secara vertikal
            marginBottom: "20vh",
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Avatar
              alt={user.nama_lengkap}
              src={preview || user.foto_profil}
              sx={{
                width: 120,
                height: 120,
                mb: 2,
                border: "3px solid white",
              }}
            />
            <Typography variant="h5" component="div" gutterBottom>
              {user.nama_lengkap}
            </Typography>
            <Typography variant="subtitle1" component="div" gutterBottom>
              {user.jabatan}
            </Typography>
          </Stack>
          {isEditing ? (
            <form onSubmit={handleSubmit}>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                Upload Foto Profile
              </Typography>
              <input type="file" onChange={handleFileChange} />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="nama_lengkap"
                label="Nama Lengkap"
                name="nama_lengkap"
                autoFocus
                value={editData.nama_lengkap}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="nomor_telepon"
                label="Nomor Telepon"
                name="nomor_telepon"
                value={editData.nomor_telepon}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="alamat"
                label="Alamat"
                name="alamat"
                value={editData.alamat}
                onChange={handleInputChange}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Simpan
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2, ml: 1 }}
                onClick={() => setIsEditing(false)}
              >
                Batal
              </Button>
            </form>
          ) : (
            <>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemText primary="Email" secondary={user.email} />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Nomor Telepon"
                    secondary={user.nomor_telepon}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Alamat" secondary={user.alamat} />
                </ListItem>
              </List>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => setIsEditing(true)}
              >
                Edit Profil
              </Button>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
