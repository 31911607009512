// produksi/src/components/sidebar.jsx
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
// Import ikon dari @mui/icons-material
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LogoutIcon from "@mui/icons-material/Logout";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useNavigate } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import theme from "../theme";

import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import WidgetsIcon from "@mui/icons-material/Widgets";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";

const drawerWidth = 240;
const drawerMinWidth = 57;

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };

  const menuItems = [
    {
      text: "Dashboard",
      path: "/",
      icon: <DashboardCustomizeOutlinedIcon />,
      iconColor: "#FFFFFF",
      textColor: "#FFFFFF",
    }, // Tambahkan ini
    {
      text: "Daftar Produk",
      path: "/products",
      icon: <WidgetsIcon />,
      iconColor: "#FFFFFF",
      textColor: "#FFFFFF",
    },
    {
      text: "Order Produksi",
      path: "/production-request",
      icon: <QueuePlayNextIcon />,
      iconColor: "#FFFFFF",
      textColor: "#FFFFFF",
    },
    {
      text: "Projek Selesai",
      path: "/selesai",
      icon: <FactCheckOutlinedIcon />,
      iconColor: "#FFFFFF",
      textColor: "#FFFFFF",
    },
  ];

  return (
    <Drawer
      sx={{
        width: open ? drawerWidth : drawerMinWidth,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : drawerMinWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.primary.main,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={toggleDrawer}>
            <ListItemIcon
              sx={{
                color: "#FFFFFF",
              }}
            >
              <FirstPageIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#FFFFFF" }}>Kinami Living</Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ borderColor: "white", marginBottom: 2 }} />
        {menuItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={item.path} sx={{ mb: 1 }}>
              <ListItemIcon sx={{ color: item.iconColor }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{ display: open ? "block" : "none", color: item.iconColor }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/profile">
            <ListItemIcon sx={{ color: "#FFFFFF" }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#FFFFFF" }}>Profil</Typography>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon sx={{ color: "#FFFFFF" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#FFFFFF" }}>Logout</Typography>
              }
            />{" "}
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
