import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Manrope, Arial, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "3.0rem",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.6rem",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.2rem",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.8rem",
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 700,
      fontSize: "1.25rem",
    },
    body1: {
      fontWeight: 400, // Normal body text
      fontSize: "1.0rem",
    },
    body2: {
      fontWeight: 400, // Sedikit lebih kecil dari body1
      fontSize: "0.875rem",
    },
    button: {
      fontWeight: 600, // Berat font untuk button
      fontSize: "0.875rem",
    },
  },

  // Anda dapat menambahkan lebih banyak penyesuaian untuk elemen-elemen lain seperti subtitle, caption, dll.

  palette: {
    primary: {
      main: "#7F6254",
    },
    secondary: {
      main: "#975A16",
    },
    background: {
      appBar: "#FFFFF0", // Tambahkan warna latar belakang AppBar di sini
    },
    third: {
      main: "#D69E2E", // Tambahkan warna ketiga di sini
    },
    yellow: {
      main: "#ECC94B", // Tambahkan warna
    },
    merah: {
      main: "#9C4221", // Tambahkan warna
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Menambahkan borderRadius untuk membuat tombol lebih bulat
          textTransform: "none", // Tambahkan styling lain yang Anda inginkan untuk tombol di sini
        },
      },
    },
    // Customisasi komponen lain jika diperlukan
  },
});

export default theme;
