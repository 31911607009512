//// produksi/src/pages/Admin/RoleGroupMapping.jsx

import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";

const RoleGroupMapping = () => {
  const [mappings, setMappings] = useState([]);

  useEffect(() => {
    // Ambil data pemetaan peran ke grup dari server

    const mappings = [
      {
        id: 1,
        role: "Administrator",
        group: "Grup Pemasaran",
      },
      {
        id: 2,
        role: "Editor",
        group: "Grup Penjualan",
      },
      {
        id: 3,
        role: "Pengguna Biasa",
        group: "Grup Keuangan",
      },
    ];

    setMappings(mappings);
  }, []);

  const handleCreateMapping = () => {
    // Buat pemetaan baru

    const newMapping = {
      id: 4,
      role: "Manajer",
      group: "Grup TI",
    };

    setMappings([...mappings, newMapping]);
  };

  const handleEditMapping = (id) => {
    // Edit pemetaan

    const mapping = mappings.find((mapping) => mapping.id === id);

    // Tampilkan dialog untuk mengedit pemetaan

    const updatedMapping = {
      ...mapping,
      role: "Manajer Proyek",
      group: "Grup Teknologi",
    };

    setMappings(
      mappings.map((mapping) => (mapping.id === id ? updatedMapping : mapping))
    );
  };

  const handleDeleteMapping = (id) => {
    // Hapus pemetaan

    setMappings(mappings.filter((mapping) => mapping.id !== id));
  };

  return (
    <Paper sx={{ maxWidth: "600px", margin: "20px auto", padding: "20px" }}>
      <h1>Pemetaan Peran ke Grup</h1>
      <Button variant="contained" onClick={handleCreateMapping}>
        Buat Pemetaan Baru
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Peran</TableCell>
            <TableCell>Grup</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mappings.map((mapping) => (
            <TableRow key={mapping.id}>
              <TableCell>{mapping.id}</TableCell>
              <TableCell>{mapping.role}</TableCell>
              <TableCell>{mapping.group}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => handleEditMapping(mapping.id)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeleteMapping(mapping.id)}
                >
                  Hapus
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default RoleGroupMapping;
