import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductCard from "../../components/ProductCard/ProductCard";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Drawer,
  Tab,
  Tabs,
  Box,
  InputAdornment, // Import tambahan
  useMediaQuery,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import theme from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon
import MenuIcon from "@mui/icons-material/Menu";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    axios
      .get("https://server.kinamiliving.com/api/categories")
      .then((response) => setCategories(response.data))
      .catch((error) => console.error("Error fetching categories:", error));

    axios
      .get("https://server.kinamiliving.com/api/products")
      .then((response) => setProducts(response.data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const handleCardClick = (productId, productName) => {
    const formattedProductName = productName.replace(/\s+/g, "-").toLowerCase();
    navigate(`/products/${formattedProductName}`, { state: { productId } });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, margin: 0, padding: 0 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar id="toolbar">
          <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
            Data Produk
          </Typography>

          {isMobile ? (
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder="Cari Produk..."
                value={searchQuery}
                onChange={handleSearchChange}
                size="small"
                sx={{
                  mr: 2,
                  width: "270px", // Menyesuaikan panjang field di sini
                  // Untuk responsive, bisa menggunakan width: '100%', maxWidth: '250px'
                  "& fieldset": {
                    paddingLeft: (theme) => theme.spacing(2.5),
                    borderRadius: "30px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon sx={{ transition: "transform 0.3s" }} />}
                onClick={() => navigate("/products/add-product")}
                sx={{
                  "&:hover": { "& svg": { transform: "rotate(45deg)" } },
                }}
              >
                Tambah Produk
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        textColor="inherit"
        indicatorColor="primary"
        scrollButtons
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="Semua Produk" />
        {categories.map((category, index) => (
          <Tab key={index} label={category.kategori} />
        ))}
      </Tabs>

      <Grid container spacing={3} sx={{ p: 2 }}>
        {products
          .filter(
            (product) =>
              (activeTab === 0 ||
                product.kategori === categories[activeTab - 1]?.kategori) &&
              product.nama.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((product) => (
            <Grid
              item
              key={product.id_produk}
              xs={6}
              sm={4}
              md={3}
              lg={2.3}
              xl={2}
            >
              <ProductCard
                name={product.nama}
                category={product.kategori}
                subcategory={product.subkategori}
                vendorLogo={product.logo_vendor}
                imageUrl={product.foto_produk}
                onClick={() => handleCardClick(product.id_produk, product.nama)}
              />
            </Grid>
          ))}
      </Grid>

      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <TextField
                variant="outlined"
                placeholder="Cari Produk..."
                value={searchQuery}
                onChange={handleSearchChange}
                size="small"
                sx={{
                  mr: 2,
                  width: "270px", // Menyesuaikan panjang field di sini
                  // Untuk responsive, bisa menggunakan width: '100%', maxWidth: '250px'
                  "& fieldset": {
                    paddingLeft: (theme) => theme.spacing(2.5),
                    borderRadius: "30px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />{" "}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/products/add-product")}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Tambah Produk" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default ProductList;
