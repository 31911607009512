import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import theme from "../../theme";
import StatusChip from "../../components/StatusChip";

const OrderDetails = () => {
  const { orderId } = useParams(); // Mengambil orderId dari URL
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        // Ganti dengan URL endpoint API Anda untuk mendapatkan detail order
        const response = await axios.get(
          `https://server.kinamiliving.com/api/orders/details/${orderId}`
        );
        setOrderDetails(response.data);
        console.log("Order Details:", response.data); // Menampilkan detail order di console browser
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  useEffect(() => {
    if (orderDetails) {
      // Format judul halaman: "Nomor Order - Nama Pelanggan - Nama Produk"
      const pageTitle = `Detail Pesanan - ${orderDetails.nama_sales} - ${orderDetails.nama_pelanggan} - ${orderDetails.nama_produk}`;
      document.title = pageTitle;
    }
  }, [orderDetails]); // Ketergantungan pada orderDetails memastikan useEffect dijalankan ulang saat data orderDetails berubah

  if (!orderDetails) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("id-ID", options).format(
      new Date(dateString)
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handlePrintAndUpdateStatus = async () => {
    try {
      // Memanggil window.print() untuk memicu dialog cetak
      window.print();

      // Mendapatkan daftar userId yang diizinkan dari server
      const response = await axios.get(
        "https://server.kinamiliving.com/api/auth/allowed-users"
      );
      const allowedUserIds = response.data.allowedUserIds;

      console.log("Allowed UserIds from Endpoint:", allowedUserIds);

      // Mendapatkan userId dari localStorage atau tempat penyimpanan yang sesuai
      const userId = Number(localStorage.getItem("userId"));

      console.log("UserId from localStorage:", userId);

      // Pemeriksaan apakah userId memiliki izin untuk memperbarui status cetak
      if (!allowedUserIds.includes(userId)) {
        console.log("Anda tidak memiliki izin untuk memperbarui status cetak.");
        return;
      }

      // Ambil orderId dari konteks yang sesuai
      // Memanggil API untuk mengupdate status cetak
      await axios.put(
        `https://server.kinamiliving.com/api/orders/cetak/${orderId}`
      );
      console.log("Status cetak updated successfully");
      alert("Status cetak berhasil diperbarui.");
    } catch (error) {
      console.error(
        "Error updating status cetak or fetching allowed users:",
        error
      );
      alert("Terjadi kesalahan saat memperbarui status cetak.");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="content-to-print print-container">
      <style>
        {`

        @media print {
          @page {
    margin: 0; /* Mengurangi margin halaman */
  }
  body {
    margin: 1cm; /* Menetapkan margin untuk konten */
  }

  body, html {
    width: 100%;
    margin: 0;
    padding: 0;
    
  }
  .container-no-wrap {
    flex-wrap: nowrap;
  }
  .MuiAppBar-root, .MuiButton-root, .MuiDrawer-root, .status-chip, .MuiChip-filled {
    display: none;
  }
  .print-container {
    width: 100%;
    
  }
  .MuiGrid-root, MuiGrid-container {
      padding-right: 6px, !important; /* Atau nilai yang Anda inginkan */

  }
  .content-to-print {
    page-break-after: never;
    overflow: visible;
  }

  .table {
  width: 100%;
  table-layout: fixed; /* Helps maintain column widths */

}

.MuiTableCell-root, .MuiTableBody-root {
    word-break: keep-all;
    font-size: 10pt; /* Sesuaikan ukuran font jika diperlukan */
    font-family: Arial, sans-serif !important;
    font-weight: bold;
}

.MuiTypography-root {
    white-space: nowrap;
    font-size: 12pt;
    font-family: Arial, sans-serif !important;
        font-weight: bold;

}

  /* Menambahkan aturan untuk mengurangi ukuran font dan padding jika diperlukan */
  .MuiGrid-root, .MuiGrid-container {
    padding-right: 6px !important; /* Hapus jika ini menyebabkan konten tidak muat */
  }
  
  /* Aturan tambahan untuk memastikan tidak ada page break yang tidak diinginkan */
  * {
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;
  }

}
  `}
      </style>

      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, color: "black" }}>
            Detail Order
          </Typography>
          <Button
            variant="contained"
            onClick={handlePrintAndUpdateStatus}
            sx={{
              marginRight: 2, // Menambahkan margin di sebelah kanan tombol
              paddingLeft: 2,
              paddingRight: 2,
              textTransform: "none",
            }}
            startIcon={<SendIcon />}
          >
            Cetak Surat Order
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: 3, ml: 3, mr: 3 }}>
        <div id="contentToPrint">
          <h1
            id="printTitle"
            style={{
              textAlign: "center",
              fontSize: "18px",
            }}
          ></h1>
        </div>

        <Grid
          container
          sx={{ marginBottom: 1, whiteSpace: "nowrap" }}
          className="container-no-wrap"
        >
          <Grid item xs={5} md={5} sx={{ pr: 2 }} gutterBottom>
            <Grid container spacing={1}>
              {/* Pasangan Nomor Order */}
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Nomor Order
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  : {orderDetails.id}
                </Typography>
              </Grid>
              {/* Pasangan Customer */}
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Customer
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  : {orderDetails.nama_pelanggan}
                </Typography>
              </Grid>
              {/* Pasangan Sales */}
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Sales
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  : {orderDetails.nama_sales}
                </Typography>
              </Grid>
              {/* Pasangan Jenis Pekerjaan */}
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Pekerjaan
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  : {orderDetails.isCustom ? "Custom" : "Standard"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={4}>
            {/* Struktur serupa untuk Date Order, Finish Date, dll. */}
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  Date Order
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" gutterBottom>
                  : {formatDate(orderDetails.tanggal_pemesanan)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  Finish Date
                </Typography>
              </Grid>
              <Grid item xs={8} display="flex" alignItems="center">
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ marginRight: 2 }}
                >
                  :{" "}
                  {orderDetails
                    ? formatDate(orderDetails.deadline_produksi)
                    : "Loading..."}
                </Typography>
                {/* Menampilkan StatusChip di samping Finish Date */}
                {orderDetails && (
                  <StatusChip
                    status={orderDetails.status}
                    className="status-chip"
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  Total Unit
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" gutterBottom>
                  : ........
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontSize: 18, fontWeight: 600 }}
            >
              Status:
            </Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Complete" />
              <FormControlLabel control={<Checkbox />} label="Tag" />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: "bold" }}
          >
            Progress:
          </Typography>
          <FormControlLabel
            control={<Checkbox />}
            label="P. Kain"
            component="span"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Jahit"
            component="span"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Rangka"
            component="span"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Jok"
            component="span"
          />
        </Grid>

        <Divider sx={{ borderTopWidth: 2, borderColor: "black" }} />

        <Paper
          elevation={0}
          sx={{
            padding: 2,
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <TableContainer sx={{ border: 1, borderRadius: "16px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ textAlign: "center" }}>
                <TableRow
                  sx={{
                    "& .MuiTableCell-root:not(:last-child)": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-head": {
                      textAlign: "center",
                      fontSize: 16,
                    },
                  }}
                >
                  <TableCell sx={{ fontWeight: 600 }}>Nama Produk</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Kategori</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Dimensi</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Jumlah</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Contoh data baris tabel, gantikan dengan data dinamis Anda */}
                <TableRow
                  key={orderDetails.id}
                  sx={{
                    "& .MuiTableCell-root:not(:last-child)": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-root": {
                      textAlign: "center",
                      fontSize: 16,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {orderDetails.nama_produk}
                  </TableCell>
                  <TableCell>
                    {orderDetails.kategori} - {orderDetails.subkategori}
                  </TableCell>
                  <TableCell>
                    {orderDetails.dimensi} cm{" "}
                    {/* Misalnya format Dimensi / Ukuran */}
                  </TableCell>
                  <TableCell>{orderDetails.jumlah}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Paper
          elevation={0}
          sx={{
            padding: 2,
            overflow: "hidden",
          }}
        >
          {" "}
          <Typography
            variant="body1"
            gutterBottom
            sx={{ fontSize: 18, fontWeight: 600 }}
          >
            Spesifikasi:
          </Typography>
          <Grid
            container
            spacing={0}
            className="container-no-wrap"
            sx={{ whiteSpace: "nowrap" }}
          >
            <Grid item xs={4} md={4}>
              <Paper
                elevation={0}
                sx={{
                  padding: 2,
                  borderRadius: "16px",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Jenis Kain
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.jenis_kain}
                    </Typography>
                  </Grid>
                  {/* Pasangan Customer */}
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Warna
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.warna}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Jenis Kaki
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.jenis_kaki}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Finishing
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.finishing}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={5} md={5}>
              <Paper
                elevation={0}
                sx={{
                  padding: 2,
                  borderRadius: "16px",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Jenis dudukan
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.jenis_dudukan}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Bantal Peluk
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.bantal_peluk}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Bantal Sandaran
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.bantal_sandaran}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Kantong Remote
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom>
                      : {orderDetails.kantong_remote}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3} md={3}>
              <Paper
                elevation={0}
                sx={{
                  padding: 2,
                  overflow: "hidden",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body1" gutterBottom>
                      Termasuk Puff : {orderDetails.puff ? "Ya" : "Tidak"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" gutterBottom></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={`Catatan Pesanan:`}
                      value={orderDetails.catatan}
                      disabled
                      multiline
                      rows={4}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Paper>

        <Divider sx={{ borderTopWidth: 2, borderColor: "black" }} />
        <Grid item xs={4} md={4}>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              borderRadius: "16px",
              overflow: "hidden",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: 2 }}
              className="container-no-wrap"
            >
              {/* Grid item untuk Progress */}
              <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 600 }}
                >
                  Dibuat oleh:
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 7 }}>
                  Operator
                </Typography>
                <Typography variant="body1">
                  {orderDetails.nama_sales}
                </Typography>
              </Grid>
              {/* Grid item untuk Status */}
              <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 600 }}
                >
                  Mengetahui:
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginBottom: 2 }}
                  className="container-no-wrap"
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" sx={{ marginBottom: 7 }}>
                      Admin Logistik
                    </Typography>
                    <Typography variant="body1">(. . . . . . . .)</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" sx={{ marginBottom: 7 }}>
                      KA Gudang
                    </Typography>
                    <Typography variant="body1">(. . . . . . . .)</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderDetails;
