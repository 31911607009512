// produksi/src/components/ProductCard/ProductCard.jsx
import * as React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";

const ProductCard = ({
  name,
  category,
  subcategory,
  vendorLogo,
  price,
  imageUrl,
  onClick,
}) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CardMedia component="img" height="140" image={imageUrl} alt={name} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {`${category} | ${subcategory}`}
        </Typography>
        <Typography variant="h6" component="div" title={name}>
          {name.length > 20
            ? name.substring(0, 20).replace(/\w+$/, "...")
            : name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {vendorLogo && (
            <img
              src={vendorLogo}
              alt="Vendor Logo"
              style={{ maxWidth: 50, maxHeight: 50 }}
            />
          )}
        </Typography>
      </CardContent>

      <Button
        variant="contained"
        sx={{
          marginLeft: "auto",
          display: "block",
          width: "100%",
          marginBottom: 0,
          borderRadius: 0,
        }}
      >
        Ajukan Produksi
      </Button>
    </Card>
  );
};

export default ProductCard;
