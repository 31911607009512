//produksi/src/components/ScheduleTable.jsx
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Container,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid, // Tambahkan ini
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
// Contoh struktur data yang mungkin Anda dapatkan dari database
// const rowsFromDatabase = [
//   { id: 1, sales: 'Mey Lisha', productName: 'Shizu 2 Seater', jobType: 'Custom' },
//   ...
// ];

const ScheduleTable = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    // Fungsi untuk mengambil data dari database
    const fetchData = async () => {
      // Anda akan membutuhkan API atau metode untuk mengambil data dari database Anda
      // const data = await fetch('/api/data');
      // const json = await data.json();
      // setRows(json);

      // Sementara ini, kita akan menggunakan data dummy
      setRows([
        {
          id: 1,
          sales: "Mey Lisha",
          productName: "Shizu 2 Seater",
          jobType: "Custom",
        },
        {
          id: 2,
          sales: "Ratna",
          productName: "Kanaba 2 Seater",
          jobType: "Standard",
        },
        // ... tambahkan data lainnya
      ]);
    };

    fetchData();
  }, []);

  const columns = [
    { field: "sales", headerName: "Sales", width: 150 },
    { field: "productName", headerName: "Nama Produk", width: 150 },
    { field: "jobType", headerName: "Jenis Pekerjaan", width: 130 },
    {
      field: "complete",
      headerName: "Tandai Selesai",
      width: 180,
      renderCell: () => (
        <Button variant="contained" color="success">
          Selesai
        </Button>
      ),
    },
    { field: "deadline", headerName: "Deadline ", width: 130 },
  ];

  return (
    <Box>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>

      <Grid item xs={12} md={8}>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Operators</TableCell>
                <TableCell>Produk</TableCell>
                <TableCell>Jenis Pekerjaan</TableCell>
                <TableCell>Tanggal Produksi</TableCell>
                <TableCell>Deadline</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tandai Selesai</TableCell> {/* Tambahkan ini */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Meong</TableCell>
                <TableCell>Meong</TableCell>
                <TableCell>Meong</TableCell>
                <TableCell>Meong</TableCell>
                <TableCell>Meong </TableCell>
                <TableCell>Meong </TableCell>
                <TableCell>Meong </TableCell>
                <TableCell>
                  {/* Tambahkan tombol Tandai Selesai */}
                  <Button variant="contained" color="primary">
                    Selesai
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default ScheduleTable;
