import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Container,
  Paper,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import theme from "../../theme";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const SingleProduct = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const { productId } = location.state || {}; // Jika tidak ada state, gunakan objek kosong sebagai fallback

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://server.kinamiliving.com/api/products/${productId}`
        );

        setProduct(response.data);
        console.log("Data produk berhasil didapatkan:", response.data); // Tambahkan log ini
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    if (productId) {
      fetchProduct();
    }
  }, [productId]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found.</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{
              margin: 1,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Detail Produk
          </Typography>
          <Button
            variant="contained"
            sx={{
              marginRight: 2, // Menambahkan margin di sebelah kanan tombol
              paddingLeft: 2,
              paddingRight: 2,
              textTransform: "none",
              backgroundColor: theme.palette.secondary.main,
            }}
            startIcon={<AdminPanelSettingsIcon />}
            onClick={() =>
              navigate("/services-product", { state: { productId } })
            }
          >
            Services / Return
          </Button>
          <Button
            variant="contained"
            sx={{
              marginRight: 2, // Menambahkan margin di sebelah kanan tombol
              paddingLeft: 2,
              paddingRight: 2,
              textTransform: "none",
            }}
            startIcon={<SendIcon />}
            onClick={() =>
              navigate("/production-form", { state: { productId } })
            }
          >
            Ajukan Produksi
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} md={6}>
            {/* Tambahkan background dan padding untuk area gambar */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={product.foto_produk} // Pastikan properti ini sesuai dengan respons API Anda
                alt={product.nama}
                style={{
                  maxWidth: "100%",
                  maxHeight: "600px",
                  borderRadius: "16px",
                }} // Atur ukuran maksimum gambar
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                minHeight: "500px",
                borderRadius: "16px",
                overflow: "hidden",
              }}
            >
              {/* Atur minHeight agar sesuai dengan tinggi area gambar */}
              <Typography variant="subtitle1">
                {`${product.kategori} | ${product.subkategori}`}
              </Typography>

              <Typography variant={isMobile ? "h5" : "h4"} fontWeight={"600"}>
                {product.nama}
              </Typography>

              <Typography variant="body1" fontWeight={"500"} gutterBottom>
                {`SKU: ${product.sku}`}
              </Typography>

              <Typography variant="body1" color="text.secondary" gutterBottom>
                {`Vendor:`}
              </Typography>
              {product.logo_vendor && (
                <img
                  src={product.logo_vendor}
                  alt="Vendor Logo"
                  style={{
                    width: "100px", // Sesuaikan ukuran logo vendor
                    height: "100%", // Sesuaikan ukuran logo vendor
                  }}
                />
              )}
              <Typography
                variant="subtitle1"
                fontWeight={"500"}
                gutterBottom
                sx={{ marginTop: 2 }}
              >
                Dimensi Ukuran:
              </Typography>

              {/* Informasi Panjang, Lebar, dan Tinggi */}
              <Grid container sx={{ marginBottom: 3 }}>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Panjang
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {`: ${product.panjang} cm`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Lebar
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {`: ${product.lebar} cm`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    Tinggi
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {`: ${product.tinggi} cm`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    Masa Garansi
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    {`: ${product.masa_garansi} Tahun`}
                  </Typography>
                </Grid>
              </Grid>

              <>
                {/* Tampilkan spesifikasi khusus untuk Sofa jika ada */}
                <>
                  <Typography variant="h6" gutterBottom>
                    Spesifikasi Khusus
                  </Typography>
                  <Grid container>
                    {/* Style Sofa */}
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Style
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.nama_style || "-"}
                      </Typography>
                    </Grid>

                    {/* Jenis Kain */}
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Jenis Kain
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.jenis_kain || "-"}
                      </Typography>
                    </Grid>

                    {/* Dudukan */}
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Dudukan
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.jenis_dudukan || "-"}
                      </Typography>
                    </Grid>

                    {/* Jenis Kaki */}
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Jenis Kaki
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.jenis_kaki || "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Jumlah Bantal Peluk
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.bantal_peluk || "-"}
                      </Typography>
                    </Grid>

                    {/* Jumlah Bantal Sandaran */}
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Jumlah Bantal Sandaran
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.bantal_sandaran || "-"}
                      </Typography>
                    </Grid>

                    {/* Jumlah Kantong Remot */}
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Kantong Remote
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.kantong_remot || "-"}
                      </Typography>
                    </Grid>

                    {/* Termasuk Puff */}
                    <Grid item xs={4}>
                      <Typography variant="body1" gutterBottom>
                        Termasuk Puff
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" gutterBottom>
                        : {product.puff ? "Ya" : "Tidak"}
                      </Typography>
                    </Grid>

                    {/* Tambahkan informasi lain seperti Bantal Peluk, Bantal Sandaran, dll., jika ada */}
                    {/* ... */}
                  </Grid>
                </>
              </>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/*
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img src={product.foto_produk} alt={product.nama} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={4}>
                <Typography variant="h4" component="h2">
                  {product.nama}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {`SKU: ${product.sku}`}
                </Typography>
                <Typography variant="body1" sx={{ mt: 4 }}>
                  <b>Informasi Produk:</b>
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary="Panjang"
                      secondary={`${product.panjang} cm`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Lebar"
                      secondary={`${product.lebar} cm`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Tinggi"
                      secondary={`${product.tinggi} cm`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Masa Garansi"
                      secondary={`${product.masa_garansi} tahun`}
                    />
                  </ListItem>
                </List>
                <Typography variant="h6" component="h3" sx={{ mt: 4 }}>
                  Spesifikasi Khusus Sofa
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary="Style"
                      secondary={product.nama_style}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Jenis Kain"
                      secondary={product.jenis_kain}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Dudukan"
                      secondary={product.jenis_dudukan}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Jenis Kaki"
                      secondary={product.jenis_kaki}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Jumlah Bantal Peluk"
                      secondary={product.bantal_peluk}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Jumlah Bantal Sandaran"
                      secondary={product.bantal_sandaran}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Termasuk Puff"
                      secondary={product.puff ? "Ya" : "Tidak"}
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      */}
    </Box>
  );
};

export default SingleProduct;
