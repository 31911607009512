// produksi/src/pages/Production/ScheduleList.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Untuk navigasi
import {
  AppBar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Checkbox,
  Button,
  TableFooter,
  Pagination,
  TablePagination,
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import theme from "../../theme";
import StatusChip from "../../components/StatusChip";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from "@mui/icons-material";

const SchedulePage = () => {
  const [delayedOrders, setDelayedOrders] = useState([]);
  const [scheduledOrders, setScheduledOrders] = useState([]);
  const navigate = useNavigate(); // Hook dari React Router untuk navigasi
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [queueServices, setQueueServices] = useState([]); // State untuk menyimpan data antrian services

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [allowedUserIds, setAllowedUserIds] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const delayedResponse = await axios.get(
          "https://server.kinamiliving.com/api/orders/delayed"
        );
        // Mengurutkan pesanan berdasarkan deadline dari yang terkecil
        const sortedDelayedOrders = delayedResponse.data.sort(
          (a, b) =>
            new Date(a.deadline_produksi) - new Date(b.deadline_produksi)
        );
        setDelayedOrders(sortedDelayedOrders);

        const scheduledResponse = await axios.get(
          "https://server.kinamiliving.com/api/orders/scheduled"
        );
        setScheduledOrders(scheduledResponse.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    fetchQueueServices();
  }, []);

  const fetchQueueServices = async () => {
    try {
      const response = await axios.get(
        "https://server.kinamiliving.com/api/orders/scheduled-services" // Endpoint baru
      );
      setQueueServices(response.data);
    } catch (error) {
      console.error("Error fetching queue services:", error);
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigasi kembali ke halaman sebelumnya
  };

  useEffect(() => {
    const reloadData = async () => {
      const response = await axios.get(
        "https://server.kinamiliving.com/api/orders/scheduled"
      );
      setScheduledOrders(response.data);
    };

    reloadData();
  }, [refreshTrigger]); // Memperhatikan perubahan pada `refreshTrigger`

  // Fungsi untuk menghitung berapa hari telat dari deadline
  const calculateDaysLate = (deadline_produksi) => {
    const today = new Date();
    const deadlineDate = new Date(deadline_produksi);
    const timeDiff = today - deadlineDate;
    const daysLate = Math.max(0, Math.ceil(timeDiff / (1000 * 60 * 60 * 24)));
    return daysLate;
  };

  // Fungsi untuk memformat tanggal ke dd-mm-yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Bulan dimulai dari 0
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleCheckboxChange = async (orderId, processName, isChecked) => {
    try {
      // Kirim permintaan ke backend untuk mengupdate status
      await axios.post(
        `https://server.kinamiliving.com/api/orders/updateStatus`,
        {
          orderId,
          columnName: processName, // Menggunakan nama kolom yang sesuai dengan backend
          status: isChecked,
        }
      );
      // Refresh data atau lakukan tindakan setelah berhasil update
      console.log(`Status updated for ${processName} to ${isChecked}`);

      // Perbarui state scheduledOrders dengan status yang baru
      const updatedOrders = scheduledOrders.map((order) => {
        if (order.order_id === orderId) {
          return { ...order, [processName]: isChecked };
        }
        return order;
      });
      setScheduledOrders(updatedOrders);
    } catch (error) {
      console.error(`Error updating status for ${processName}:`, error);
      // Tampilkan pesan error atau lakukan tindakan error handling
    }
  };

  const handleServiceCheckboxChange = async (
    serviceId,
    columnName,
    isChecked
  ) => {
    try {
      // Kirim permintaan ke backend untuk mengupdate status layanan
      await axios.post(
        `https://server.kinamiliving.com/api/orders/service-updateStatus`,
        {
          serviceId,
          columnName,
          status: isChecked,
        }
      );
      // Refresh data atau lakukan tindakan setelah berhasil update
      console.log(`Status updated for ${columnName} to ${isChecked}`);

      // Perbarui state queueServices dengan status yang baru
      const updatedQueueServices = queueServices.map((service) => {
        if (service.service_id === serviceId) {
          return { ...service, [columnName]: isChecked };
        }
        return service;
      });
      setQueueServices(updatedQueueServices);
    } catch (error) {
      console.error(`Error updating status for ${columnName}:`, error);
      // Tampilkan pesan error atau lakukan tindakan error handling
    }
  };

  const handleUpdateStatus = async (orderId, columnName, status) => {
    try {
      // Kirim permintaan ke backend untuk mengupdate status
      console.log("Sending updateStatus request with:", {
        orderId,
        columnName: "selesai",
        status: true,
      });
      const response = await axios.post(
        `https://server.kinamiliving.com/api/orders/updateStatus`,
        {
          orderId, // ID order yang akan diperbarui
          columnName, // Nama kolom yang akan diperbarui, "selesai" dalam kasus ini
          status, // Status baru, true dalam kasus ini
        }
      );
      // Cek respons dan lakukan tindakan yang sesuai
      if (response.data.success) {
        console.log("Status successfully updated");
        // Opsional: Refresh data atau navigasi pengguna
      }
    } catch (error) {
      console.error("Error updating status:", error);
      // Tampilkan pesan error atau lakukan tindakan error handling lainnya
    }
  };

  const toKebabCase = (str) => {
    return str
      .toLowerCase() // Ubah menjadi huruf kecil
      .replace(/[\s_]+/g, "-") // Ganti spasi atau underscore dengan tanda strip
      .replace(/[^\w-]+/g, "") // Hapus semua karakter selain huruf, angka, dan tanda strip
      .replace(/--+/g, "-"); // Ganti multiple tanda strip dengan satu
  };

  const handleRowClick = (namaPelanggan, namaProduk, orderId) => {
    const formattedNamaPelanggan = namaPelanggan
      ? toKebabCase(namaPelanggan)
      : "unknown-customer";
    const formattedNamaProduk = namaProduk
      ? toKebabCase(namaProduk)
      : "unknown-product";
    navigate(
      `/order-details/${formattedNamaPelanggan}/${formattedNamaProduk}/${orderId}`
    );
  };

  const handleServiceListRowClick = (serviceId) => {
    // Logika navigasi atau tindakan lain ketika baris pada servicesList diklik
    // Contoh: navigate ke detail service dengan serviceId sebagai parameter
    navigate(`/service-details/${serviceId}`);
  };

  const [currentOrder, setCurrentOrder] = useState(null);
  const handleOpenDialog = (order) => {
    setOpenDialog(true);
    setCurrentOrder(order); // Pastikan ini memasukkan order_id yang benar
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCompletionDate(""); // Reset tanggal selesai
    setSelectedFile(null); // Reset file terpilih
    setCurrentOrder(null); // Reset order yang sedang diproses
  };

  const userId = Number(localStorage.getItem("userId"));

  const handleUploadBukti = async (order) => {
    const formData = new FormData();
    formData.append("foto", selectedFile); // 'selectedFile' adalah state yang menyimpan file yang dipilih
    formData.append("idOrder", order.order_id); // Konversi idOrder ke string jika perlu
    formData.append("completionDate", completionDate); // Tambahkan tanggal selesai ke FormData
    formData.append("namaProduk", order.product_name);
    formData.append("userId", userId); // Contoh menambahkan userId ke FormData

    try {
      const response = await axios.post(
        "https://server.kinamiliving.com/api/orders/uploadBukti",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Bukti berhasil diupload:", response.data);
      alert(response.data.message); // Assuming server returns a message field

      // Anda bisa melakukan sesuatu dengan response di sini, seperti menutup dialog
    } catch (error) {
      console.error("Error uploading bukti:", error);
      alert("Error uploading bukti. Tolong hubungi tim developer.");
    }

    setRefreshTrigger((oldValue) => oldValue + 1);
  };

  const [completionDate, setCompletionDate] = useState(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`; // format yyyy-MM-dd
  });

  console.log(scheduledOrders);

  useEffect(() => {
    axios
      .get("https://server.kinamiliving.com/api/auth/allowed-users")
      .then((response) => {
        if (
          !response ||
          !response.data ||
          response.data.allowedUserIds === undefined
        ) {
          console.error("Invalid response format or missing allowedUserIds");
          return;
        }

        const allowedUserIds = response.data.allowedUserIds;
        console.log("Allowed UserIds from Endpoint:", allowedUserIds);

        // Mendapatkan userId dari localStorage
        const userId = Number(localStorage.getItem("userId"));
        console.log("Current User ID:", userId);

        // Pemeriksaan apakah userId memiliki izin
        if (!allowedUserIds.includes(userId)) {
          console.log("User does not have permission.");
          return;
        }

        // Lanjutkan dengan logika untuk pengguna yang diizinkan di sini
        setAllowedUserIds(allowedUserIds);
      })
      .catch((error) => {
        console.error("Error fetching allowed user IDs:", error);
        // Handle error fetching allowed user IDs
      });
  }, []); // Perhatikan bahwa array dependensi kosong agar useEffect hanya dieksekusi sekali

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ marginRight: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Progress Produksi{" "}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" gutterBottom>
          Pekerjaan Tertunda
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "calc(6 * 57px)",
            overflowY: "auto",
            marginBottom: 4,
          }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: theme.palette.merah.main,
                position: "sticky",
                top: 0,
              }}
            >
              <TableRow>
                <TableCell sx={{ color: "white" }}>ID</TableCell>
                <TableCell sx={{ color: "white" }}>Sales</TableCell>
                <TableCell sx={{ color: "white" }}>Produk</TableCell>
                <TableCell sx={{ color: "white" }}>Pekerjaan</TableCell>
                <TableCell sx={{ color: "white" }}>Deadline</TableCell>
                <TableCell sx={{ color: "white" }}>Telat (Hari)</TableCell>
                <TableCell sx={{ color: "white" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {delayedOrders.map((order) => (
                <TableRow
                  key={order.order_id}
                  onClick={() =>
                    handleRowClick(
                      order.nama_pelanggan,
                      order.product_name,
                      order.order_id
                    )
                  }
                  hover
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{order.order_id}</TableCell>
                  <TableCell>{order.sales}</TableCell>
                  <TableCell>{order.product_name}</TableCell>
                  <TableCell>{order.job_type}</TableCell>
                  <TableCell>{formatDate(order.deadline_produksi)}</TableCell>
                  <TableCell>
                    {calculateDaysLate(order.deadline_produksi)}
                  </TableCell>
                  <TableCell>
                    <StatusChip status={order.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" gutterBottom>
          Antrian Services & Return{" "}
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "calc(6 * 57px)",
            overflowY: "auto",
            marginBottom: 4,
          }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: theme.palette.primary.main,
                position: "sticky",
                top: 0,
              }}
            >
              <TableRow>
                <TableCell sx={{ color: "white" }}>ID Services</TableCell>
                <TableCell sx={{ color: "white" }}>Sales</TableCell>
                <TableCell sx={{ color: "white" }}>Produk</TableCell>
                <TableCell sx={{ color: "white" }}>Pekerjaan</TableCell>
                <TableCell sx={{ color: "white" }}>Pengajuan</TableCell>
                <TableCell sx={{ color: "white" }}>Proses</TableCell>
                <TableCell sx={{ color: "white" }}>Selesai</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queueServices.map((service) => (
                <TableRow
                  key={service.service_id}
                  onClick={() => handleServiceListRowClick(service.service_id)}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{service.service_id}</TableCell>
                  <TableCell>{service.sales}</TableCell>
                  <TableCell>{service.product_name}</TableCell>
                  <TableCell>{service.jenis_pekerjaan}</TableCell>
                  <TableCell>{formatDate(service.tanggal_pengajuan)}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={service.proses} // Menggunakan nilai dari backend
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleServiceCheckboxChange(
                          service.service_id,
                          "proses",
                          e.target.checked // Menggunakan nilai yang dipilih oleh pengguna
                        )
                      }
                      disabled={
                        !allowedUserIds.includes(userId) || service.selesai // Dinonaktifkan jika order sudah selesai
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={service.selesai} // Menggunakan nilai dari backend
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleServiceCheckboxChange(
                          service.service_id,
                          "selesai",
                          e.target.checked // Menggunakan nilai yang dipilih oleh pengguna
                        )
                      }
                      disabled={!allowedUserIds.includes(userId)} // Dinonaktifkan jika pengguna tidak memiliki izin
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" gutterBottom>
          Jadwal Pekerjaan{" "}
        </Typography>
        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
              <TableRow>
                <TableCell sx={{ color: "white" }}>ID</TableCell>
                <TableCell sx={{ color: "white" }}>Sales</TableCell>
                <TableCell sx={{ color: "white" }}>Produk</TableCell>
                <TableCell sx={{ color: "white" }}>Deadline</TableCell>
                <TableCell sx={{ color: "white" }}>P Kain</TableCell>
                <TableCell sx={{ color: "white" }}>Jahit</TableCell>
                <TableCell sx={{ color: "white" }}>Rangka</TableCell>
                <TableCell sx={{ color: "white" }}>Jok</TableCell>
                <TableCell sx={{ color: "white" }}>Selesai</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduledOrders.map((order) => (
                <TableRow
                  key={order.order_id}
                  onClick={() =>
                    handleRowClick(
                      order.nama_pelanggan,
                      order.product_name,
                      order.order_id
                    )
                  }
                  hover // Optional: Tambahkan efek hover untuk feedback visual
                  style={{ cursor: "pointer" }} // Optional: Ubah cursor menjadi pointer untuk indikasi dapat diklik
                >
                  {" "}
                  <TableCell>{order.order_id}</TableCell>
                  <TableCell>{order.sales}</TableCell>
                  <TableCell>{order.product_name}</TableCell>
                  <TableCell>{formatDate(order.deadline_produksi)}</TableCell>
                  {/* Asumsikan Anda memiliki status proses sebagai boolean di order */}
                  <TableCell>
                    <Checkbox
                      checked={order.potong_kain}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleCheckboxChange(
                          order.order_id,
                          "potong_kain",
                          e.target.checked
                        )
                      }
                      disabled={
                        !allowedUserIds.includes(userId) || order.potong_kain
                      } // Perbaikan disini
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={order.jahit}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleCheckboxChange(
                          order.order_id,
                          "jahit",
                          e.target.checked
                        )
                      }
                      disabled={!allowedUserIds.includes(userId) || order.jahit}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={order.rangka}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleCheckboxChange(
                          order.order_id,
                          "rangka",
                          e.target.checked
                        )
                      }
                      disabled={
                        !allowedUserIds.includes(userId) || order.rangka
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={order.jok}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleCheckboxChange(
                          order.order_id,
                          "jok",
                          e.target.checked
                        )
                      }
                      disabled={!allowedUserIds.includes(userId) || order.jok}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDialog(order);
                      }}
                      disabled={
                        !(
                          order.potong_kain &&
                          order.jahit &&
                          order.rangka &&
                          order.jok
                        ) || !allowedUserIds.includes(userId)
                      }
                    >
                      Selesai
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Update Status Selesai</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="completionDate"
              label="Tanggal Selesai"
              type="date"
              fullWidth
              variant="standard"
              value={completionDate}
              onChange={(e) => setCompletionDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              style={{ marginTop: "20px" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={async () => {
              if (currentOrder) {
                console.log(currentOrder); // Debug untuk memastikan currentOrder memiliki semua data yang dibutuhkan
                await handleUploadBukti(currentOrder);
                await handleUpdateStatus(
                  currentOrder.order_id, // Pastikan ini sesuai dengan properti yang ada di currentOrder
                  "selesai",
                  true
                );
              }
              handleCloseDialog();
            }}
          >
            Upload dan Simpan
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SchedulePage;
