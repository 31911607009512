import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  InputLabel,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import theme from "../../theme";
import StatusChip from "../../components/StatusChip";

const ServicesDetails = () => {
  const { serviceId } = useParams(); // Mengambil serviceId dari URL
  const [serviceDetails, setServiceDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        // Ganti dengan URL endpoint API Anda untuk mendapatkan detail layanan
        const response = await axios.get(
          `https://server.kinamiliving.com/api/orders/servicesdetails/${serviceId}`
        );
        setServiceDetails(response.data);
        console.log("Service Details:", response.data); // Menampilkan detail layanan di console browser
      } catch (error) {
        console.error("Error fetching service details:", error);
      }
    };

    fetchServiceDetails();
  }, [serviceId]);

  useEffect(() => {
    if (serviceDetails) {
      // Format judul halaman: "Nama Produk - Nama Pelanggan - Status"
      const pageTitle = `Detail Layanan - ${serviceDetails.product_name} - ${serviceDetails.customer_name} - ${serviceDetails.status}`;
      document.title = pageTitle;
    }
  }, [serviceDetails]); // Ketergantungan pada serviceDetails memastikan useEffect dijalankan ulang saat data serviceDetails berubah

  if (!serviceDetails) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("id-ID", options).format(
      new Date(dateString)
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handlePrintAndUpdateStatus = async () => {
    try {
      // Memanggil window.print() untuk memicu dialog cetak
      window.print();

      // Mendapatkan daftar userId yang diizinkan dari server
      const response = await axios.get(
        "https://server.kinamiliving.com/api/auth/allowed-users"
      );
      const allowedUserIds = response.data.allowedUserIds;

      console.log("Allowed UserIds from Endpoint:", allowedUserIds);

      // Mendapatkan userId dari localStorage atau tempat penyimpanan yang sesuai
      const userId = Number(localStorage.getItem("userId"));

      console.log("UserId from localStorage:", userId);

      // Pemeriksaan apakah userId memiliki izin untuk memperbarui status cetak
      if (!allowedUserIds.includes(userId)) {
        console.log("Anda tidak memiliki izin untuk memperbarui status cetak.");
        return;
      }

      // Ambil serviceId dari konteks yang sesuai
      // Memanggil API untuk mengupdate status cetak layanan
      await axios.put(
        `https://server.kinamiliving.com/api/orders/servicescetak/${serviceId}`
      );
      console.log("Status cetak updated successfully");
    } catch (error) {
      console.error(
        "Error updating status cetak or fetching allowed users:",
        error
      );
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="content-to-print print-container">
      <style>
        {`

        @media print {
          @page {
    margin: 0; /* Mengurangi margin halaman */
  }
  body {
    margin: 1cm; /* Menetapkan margin untuk konten */
  }

  body, html {
    width: 100%;
    margin: 0;
    padding: 0;
    
  }
  .container-no-wrap {
    flex-wrap: nowrap;
  }
  .MuiAppBar-root, .MuiButton-root, .MuiDrawer-root, .status-chip, .MuiChip-filled {
    display: none;
  }
  .print-container {
    width: 100%;
    
  }
  .MuiGrid-root, MuiGrid-container {
      padding-right: 6px, !important; /* Atau nilai yang Anda inginkan */

  }
  .content-to-print {
    page-break-after: never;
    overflow: visible;
  }

  .table {
  width: 100%;
  table-layout: fixed; /* Helps maintain column widths */

}

.MuiTableCell-root, .MuiTableBody-root {
    word-break: keep-all;
    font-size: 10pt; /* Sesuaikan ukuran font jika diperlukan */
    font-family: Arial, sans-serif !important;
    font-weight: bold;
}

.MuiTypography-root {
    white-space: nowrap;
    font-size: 12pt;
    font-family: Arial, sans-serif !important;
        font-weight: bold;

}

  /* Menambahkan aturan untuk mengurangi ukuran font dan padding jika diperlukan */
  .MuiGrid-root, .MuiGrid-container {
    padding-right: 6px !important; /* Hapus jika ini menyebabkan konten tidak muat */
  }
  
  /* Aturan tambahan untuk memastikan tidak ada page break yang tidak diinginkan */
  * {
    page-break-inside: avoid;
    page-break-after: auto;
    page-break-before: auto;
  }

}
  `}
      </style>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        {" "}
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleBack}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Detail Services / Return
          </Typography>
          <Button
            variant="contained"
            onClick={handlePrintAndUpdateStatus}
            sx={{
              marginRight: 2, // Menambahkan margin di sebelah kanan tombol
              paddingLeft: 2,
              paddingRight: 2,
              textTransform: "none",
            }}
            startIcon={<SendIcon />}
          >
            Cetak Surat Order
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: 3, ml: 3, mr: 3 }}>
        <Grid
          container
          sx={{ marginBottom: 1, whiteSpace: "nowrap" }}
          className="container-no-wrap"
        >
          {" "}
          {/* Grid sebelah kiri */}
          <Grid item xs={3} md={3} sx={{ pr: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {" "}
                  Nomor
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {" "}
                  : SK - {serviceDetails.service_id}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  Pekerjaan
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {" "}
                  : {serviceDetails.jenis_pekerjaan}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Nomor Pesanan{" "}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {" "}
                  : SO - {serviceDetails.nomor_pesanan}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3} sx={{ pr: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  Nama Produk
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                >
                  {" "}
                  : {serviceDetails.product_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Nama Pelanggan{" "}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  {" "}
                  : {serviceDetails.nama_pelanggan}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Total Unit{" "}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  : . . .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3} sx={{ pr: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Typography variant="body1" gutterBottom>
                  Tanggal Pengajuan{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" gutterBottom>
                  {" "}
                  : {formatDate(serviceDetails.tanggal_pengajuan)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" gutterBottom>
                  Status{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Complete" />
                  <FormControlLabel control={<Checkbox />} label="Tag" />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ borderTopWidth: 2, borderColor: "black" }} />

        <Paper
          elevation={0}
          sx={{
            padding: 2,
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={3} md={3} sx={{ pr: 2 }}>
              <Box>
                <Typography variant="body1" gutterBottom>
                  Foto Kerusakan
                </Typography>
                <img
                  src={serviceDetails.bukti_foto}
                  style={{ width: "250px", height: "250px" }}
                  alt="Bukti Foto"
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sx={{ pr: 2 }}>
              <TextField
                label="Bagian yang Rusak"
                value={serviceDetails.damaged_parts}
                multiline
                rows={3}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Area kerusakan"
                value={serviceDetails.damaged_areas}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} md={3} sx={{ pr: 2 }}>
              <TextField
                label="Deskripsi Kerusakan"
                value={serviceDetails.catatan}
                multiline
                rows={6}
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
        <Divider sx={{ borderTopWidth: 2, borderColor: "black" }} />
        <Grid item xs={4} md={4}>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              borderRadius: "16px",
              overflow: "hidden",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: 2 }}
              className="container-no-wrap"
            >
              {/* Grid item untuk Progress */}
              <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 600 }}
                >
                  Dibuat oleh:
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 7 }}>
                  Operator
                </Typography>
                <Typography variant="body1">
                  {serviceDetails.operator_name}
                </Typography>
              </Grid>
              {/* Grid item untuk Status */}
              <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: 18, fontWeight: 600 }}
                >
                  Mengetahui:
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginBottom: 2 }}
                  className="container-no-wrap"
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" sx={{ marginBottom: 7 }}>
                      Admin Logistik
                    </Typography>
                    <Typography variant="body1">(. . . . . . . .)</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" sx={{ marginBottom: 7 }}>
                      KA Gudang
                    </Typography>
                    <Typography variant="body1">(. . . . . . . .)</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </Box> // Konten halaman detail layanan
  );
};

export default ServicesDetails;
