import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  ThemeProvider,
  Typography,
  Grid,
} from "@mui/material";
import Logo from "../../assets/logo-kinami-living.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import theme from "../../theme";

const Register = () => {
  const [credentials, setCredentials] = useState({
    name: "",
    username: "",
    email: "",
    phone_number: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [suggestedUsernames, setSuggestedUsernames] = useState([]);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if (credentials.password === credentials.confirmPassword) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  }, [credentials.password, credentials.confirmPassword]);

  const handleChange = (e) => {
    if (e.target.name === "name") {
      const words = e.target.value.split(" ");
      const suggestedUsernames = words.map((word) => {
        return word.toLowerCase();
      });
      setSuggestedUsernames(suggestedUsernames);
      setCredentials({
        ...credentials,
        name: e.target.value,
        username: e.target.value.toLowerCase().replace(/\s/g, ""),
      });
    } else {
      setCredentials({
        ...credentials,
        [e.target.name]: e.target.value,
      });
    }
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordError) {
      try {
        await axios.post(
          "https://server.kinamiliving.com/api/auth/register",
          credentials
        );
        // Hapus localStorage.setItem("authToken", response.data.token);
        navigate("/login"); // Arahkan ke halaman login
      } catch (error) {
        console.error("Registration failed:", error.response || error);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: "400px",
            padding: "30px",
            borderRadius: "10px",
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Logo} alt="Kinami Living" width="150px" />
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nama Lengkap"
              name="name"
              value={credentials.name}
              onChange={handleChange}
              helperText="Contoh: Miftahul Aziz"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={credentials.username}
              disabled
            />
            {suggestedUsernames.length > 0 && (
              <Typography variant="caption" sx={{ mb: 2 }}>
                Saran Username:
              </Typography>
            )}
            {suggestedUsernames.map((username, index) => (
              <Button
                key={index}
                variant="outlined"
                size="small"
                sx={{ mr: 1, mb: 1 }}
                onClick={() => {
                  setCredentials({
                    ...credentials,
                    username: username,
                  });
                }}
              >
                {username}
              </Button>
            ))}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              helperText="Contoh: aziz.devop@gmail.com"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone_number"
              label="Nomor Telepon"
              name="phone_number"
              value={credentials.phone_number}
              onChange={handleChange}
              helperText="Contoh: 085158660805"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Kata Sandi"
              name="password"
              type="password"
              value={credentials.password}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Konfirmasi Kata Sandi"
              name="confirmPassword"
              type="password"
              value={credentials.confirmPassword}
              onChange={handleChange}
            />
            {passwordError && (
              <Typography variant="caption" color="error">
                Kata sandi tidak cocok
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Daftar
            </Button>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Register;
