// produksi/src/pages/Dashboard.jsx

import React from "react";
import ScheduleTable from "../components/ScheduleTable";
import theme from "../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid, // Tambahkan ini
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";

const Dashboard = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const today = new Date();
  const formattedDate = new Intl.DateTimeFormat("id-ID", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(today);

  return (
    <Box sx={{ flexGrow: 1, margin: 0, padding: 0 }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.appBar,
          color: "black",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{}}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black" }}
          >
            Dashboard{" "}
          </Typography>
          <Typography variant="body2" component="h2" sx={{ fontWeight: 600 }}>
            {formattedDate} {/* Gunakan variabel tanggal yang diformat */}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ ml: 5, mr: 2, mt: 2 }}>
        <Grid container spacing={3}>
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            Deadline Terdekat
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: "#EFEFEF" }}>
                <TableRow>
                  <TableCell>Sales</TableCell>
                  <TableCell>Produk</TableCell>
                  <TableCell>Jenis Pekerjaan</TableCell>
                  <TableCell>Deadline</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tandai Selesai</TableCell> {/* Tambahkan ini */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>
                    {/* Tambahkan tombol Tandai Selesai */}
                    <Button variant="contained" color="primary">
                      Selesai
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>
                    {/* Tambahkan tombol Tandai Selesai */}
                    <Button variant="contained" color="primary">
                      Selesai
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>
                    {/* Tambahkan tombol Tandai Selesai */}
                    <Button variant="contained" color="primary">
                      Selesai
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>
                    {/* Tambahkan tombol Tandai Selesai */}
                    <Button variant="contained" color="primary">
                      Selesai
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>
                    {/* Tambahkan tombol Tandai Selesai */}
                    <Button variant="contained" color="primary">
                      Selesai
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong</TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>Meong </TableCell>
                  <TableCell>
                    {/* Tambahkan tombol Tandai Selesai */}
                    <Button variant="contained" color="primary">
                      Selesai
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* Jadwal hari ini */}
        </Grid>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundColor: "#F5F9F5",
              p: "24px",
              m: "24px",
              borderRadius: "10px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Deadline Terdekat
            </Typography>
            <ScheduleTable />
          </Grid>

          {/* Grafik Produktivitas 7 Hari Terakhir */}
          <Grid item xs={12} md={6}>
            {/* Komponen Chart akan ditempatkan di sini */}
          </Grid>

          {/* Persentase Pekerjaan */}
          <Grid item xs={12} md={6}>
            {/* Komponen JobPercentage akan ditempatkan di sini */}
          </Grid>
        </Grid>
      </Box>
      <Grid item xs={12} md={8}></Grid>
    </Box>
  );
};

export default Dashboard;
